import * as globals from "./globalDef";
import * as globalsVersions from "./globalDefVersions";
import * as userLib from "../libs/user-lib";
import * as dateConvert from "../libs/toDateTimeInfoString";
import {attrib_suffix, emailId, userName} from "./globalDefUsers";
import {adminName, clinicID, clinicName, clinicNameShort, suspended, adminID, clinicType} from "./globalDefClinics";
import {
    trainerDateCertified,
    trainerEmail,
    trainerID,
    trainerName,
    locale,
    trainingType,
    closedLoopSystem,
    certificationMethod
} from "./globalDefTrainers";
import {
    discountID,
    discountDurationMonths,
    discountPercentage,
    expiryDateDiscount,
    itemsAvailable
} from "./globalDefDiscounts";

/**
 * ATUK distributor ID
 */
const atukDistributorId = '84412230058';

/**
 * Default pump serial number
 * @type {string}
 */
export const defaultSN = 'XXXX';

/**
 * Default user ID
 * @type {string}
 */
export const defaultUser = 'XXXX@gmail.com';

/**
 * Default clinic ID
 * @type {string}
 */
export const defaultClinic = '9ccddd0019';

/**
 * Default trainer ID
 * @type {string}
 */
export const defaultTrainer = 'XXNNNNXX';

/**
 * Default discount ID
 * @type {string}
 */
export const defaultDiscount = 'MMMYYYY';

/**
 * Default version
 * @type {string}
 */
export const defaultVersion = 'XXXX';

/**
 * Checks if user belongs to AdminEditor group
 * @param dataGroups Data holding group membership
 * @returns {boolean} Whether user belongs to AdminEditor group
 */
export function isAdminEditor(dataGroups) {
    let found = false;
    let group;
    if (dataGroups) {
        for (group of dataGroups) {
            if (group.toLowerCase() === 'AdminEditor'.toLowerCase()) {
                found = true;
                break;
            }
        }
    }
    return found;
}

/**
 * Checks if user belongs to AdminEditor or UserEditor group
 * @param dataGroups Data holding group membership
 * @returns {boolean} Whether user belongs to AdminEditor or UserEditor group
 */
export function isUserEditor(dataGroups) {
    let found = false;
    let group;
    if (dataGroups) {
        for (group of dataGroups) {
            if (group.toLowerCase() === 'AdminEditor'.toLowerCase()) {
                found = true;
                break;
            } else if (group.toLowerCase() === 'UserEditor'.toLowerCase()) {
                found = true;
                break;
            }
        }
    }
    return found;
}

/**
 * Checks if user belongs to AdminViewer group
 * @param dataGroups Data holding group membership
 * @returns {boolean} Whether user belongs to AdminViewer group
 */
export function isAdminViewer(dataGroups) {
    let found = false;
    let group;
    if (dataGroups) {
        for (group of dataGroups) {
            if (group.toLowerCase() === 'AdminViewer'.toLowerCase()) {
                found = true;
                break;
            }
        }
    }
    return found;
}

/**
 * Checks if user belongs to Editor group
 * @param dataGroups Data holding group membership
 * @returns {boolean} Whether user belongs to Editor group
 */
export function isEditor(dataGroups) {
    let found = false;
    let group;
    if (dataGroups) {
        for (group of dataGroups) {
            if (group.toLowerCase() === 'Editor'.toLowerCase()) {
                found = true;
                break;
            }
        }
    }
    return found;
}

/**
 * Get custom attribute for user custom attribute
 * @param user User data
 * @param attributeName Attribute name (does not include "custom:" prefix)
 * @returns {boolean} Value of custom attribute or null if none exists
 */
export function getCustomAttribute(user, attributeName) {
    let value = null;
    if ((user) && (user.attributes['custom:' + attributeName])) {
        value = (user.attributes['custom:' + attributeName]);
    }
    return value;
}

/**
 * Checks if user belongs to Editors for custom attribute
 * @param user User data
 * @param attributeName Attribute name (does not include "custom:" prefix)
 * @returns {boolean} Whether user belongs to Editors for custom attribute
 */
export function isCustomEditor(user, attributeName) {
    let found = false;
    if ((user) && (user.attributes['custom:' + attributeName])) {
        const value = (user.attributes['custom:' + attributeName]);
        if (value.trim().toLowerCase() === 'Editor'.toLowerCase()) {
            found = true;
        }
    }
    return found;
}

/**
 * Checks if user belongs to Viewers for custom attribute
 * @param user User data
 * @param attributeName Attribute name (does not include "custom:" prefix)
 * @returns {boolean} Whether user belongs to Viewers for custom attribute
 */
export function isCustomViewer(user, attributeName) {
    let found = false;
    if ((user) && (user.attributes['custom:' + attributeName])) {
        const value = (user.attributes['custom:' + attributeName]);
        if (value.trim().toLowerCase() === 'Viewer'.toLowerCase()) {
            found = true;
        }
    }
    return found;
}

/**
 * Update the number of remaining days for pump use
 * @param pumps Pumps to process
 */
export function calculateDaysLeftArray(pumps) {
    let pump;
    if (pumps.Items) {
        for (pump of pumps.Items) {
            pump[globals.remainingDays] = calculateDaysLeft(pump[globals.startDate], pump[globals.expiryDate]);

            pump[globals.createdAt] = dateConvert.toDateTimeInfoString(pump[globals.createdAt]).toLocaleString().replace(/,/g, " ");
            pump[globals.modifiedAt] = dateConvert.toDateTimeInfoString(pump[globals.modifiedAt]).toLocaleString().replace(/,/g, " ");
        }
    }
}

/**
 * Get number of days for pump use
 * @param startDateVal Start day of pum use (YYYY-MM-DD)
 * @param expiryDateVal Expiry day of pum use (YYYY-MM-DD)
 * @returns {number} The remaining number of pump use
 */
export function calculateDaysLeft(startDateVal, expiryDateVal) {
    let result = 0;
    if ((startDateVal) && (expiryDateVal)) {
        const startDate = new Date(startDateVal);
        let st = new Date();
        if (st < startDate) {
            st = startDate;
        }
        result = Math.ceil((new Date(expiryDateVal) -
            st) / (1000 * 3600 * 24));
        if (result < 0) {
            result = 0;
        }
    }
    return result;
}

/**
 * Validate date
 * @param value Value to check
 * @returns {boolean} Whether date is valid
 */
export function validateDate(value) {
    const regEx = /^\d{4}-\d{2}-\d{2}$/;
    // Match the date format through regular expression
    const invalidDate = 'Invalid date format - use YYYY-MM-DD';
    if (!value || (typeof value !== 'string')) {
        alert(invalidDate);
        return false;
    }

    if (!value.match(regEx)) {
        alert(invalidDate);
        return false;
    }
    const d = new Date(value);
    const dNum = d.getTime();
    if (!dNum && dNum !== 0) {
        // NaN value, Invalid date
        alert(invalidDate);
        return false;
    }
    if (d.toISOString().slice(0, 10) === value) {
        return true;
    } else {
        alert(invalidDate);
        return false;
    }
}

export function validateForm(self) {
    let result = true;
    if (self.gridApi) {
        const el = self.gridApi.getDisplayedRowAtIndex(0).data;
        if ((el[globals.pumpSN] === defaultSN)) {
            result = false;
            alert("Incorrect pump SN")
        } else if (!userLib.validateDate(el[globals.startDate])) {
            result = false;
        } else if (!userLib.validateDate(el[globals.expiryDate])) {
            result = false;
        } else if (!el[globals.pumpMake]) {
            alert("Incorrect pump make");
            result = false;
        } else if (!el[globals.variant]) {
            alert("Incorrect variant");
            result = false;
        } else if (!el[globals.isTrained] && (el[globals.isTrained] !== false)) {
            alert("Incorrect trained status");
            result = false;
        }
    }
    return result; // self.state.content.length > 0;
}

export function validateFormUser(self) {
    let result = true;
    if (self.gridApi) {
        const el = self.gridApi.getDisplayedRowAtIndex(0).data;
        if ((!el[emailId]) || (el[emailId] === defaultUser) || (!validateEmail(el[emailId]))) {
            result = false;
            alert("Invalid user email")
        } else if (!el[userName] || (el[userName].length < 3)) {
            alert("Incorrect name");
            result = false;
        }
    }
    return result; // self.state.content.length > 0;
}

export function validateFormClinic(self) {
    let result = true;
    if (self.gridApi) {
        const el = self.gridApi.getDisplayedRowAtIndex(0).data;
        if ((!el[clinicID]) || (el[clinicID] === defaultClinic)) {
            result = false;
            alert("Invalid clinic ID")
        } else if (!validateEmail(el[adminID])) {
            result = false;
            alert("Invalid administrator email")
        } else if (!el[clinicType]) {
            alert("Incorrect data access field");
            result = false;
        } else if (!el[adminName] || (el[adminName].length < 3)) {
            alert("Incorrect administrator name");
            result = false;
        } else if (!el[clinicName] || (el[clinicName].length < 3)) {
            alert("Incorrect clinic name");
            result = false;
        } else if (!el[clinicNameShort] || (el[clinicNameShort].length < 3)) {
            alert("Incorrect short clinic name");
            result = false;
        } else if (!el[suspended] || (el[suspended].length < 3)) {
            el[suspended] = 'false';
        }
    }
    return result; // self.state.content.length > 0;
}


export function validateFormTrainer(self) {
    let result = true;
    if (self.gridApi) {
        const el = self.gridApi.getDisplayedRowAtIndex(0).data;
        if ((!el[trainerID]) || (el[trainerID] === defaultTrainer)) {
            result = false;
            alert("Invalid trainer ID")
        } else if (!validateEmail(el[trainerEmail])) {
            result = false;
            alert("Invalid trainer email")
        } else if (!el[trainerName] || (el[trainerName].length < 3)) {
            alert("Incorrect trainer name");
            result = false;
        } else if (!el[certificationMethod] || (el[certificationMethod].length < 3)) {
            alert("Incorrect certification method");
            result = false;
        } else if (!el[trainerDateCertified] || (el[trainerDateCertified].length < 3)) {
            alert("Incorrect date of certification");
            result = false;
        } else if (!el[locale] || (el[locale].length < 2)) {
            alert("Incorrect language");
            result = false;
        } else if (!el[trainingType] || (el[trainingType].length < 3)) {
            alert("Incorrect record type");
            result = false;
        } else if (!el[closedLoopSystem] || (el[closedLoopSystem].length < 2)) {
            alert("Incorrect closed loop system");
            result = false;
        } else if (!el[suspended] || (el[suspended].length < 3)) {
            el[suspended] = 'false';
        }
    }
    return result; // self.state.content.length > 0;
}


export function validateFormDiscount(self) {
    let result = true;
    if (self.gridApi) {
        const el = self.gridApi.getDisplayedRowAtIndex(0).data;
        if ((!el[discountID]) || (el[discountID] === defaultDiscount)) {
            result = false;
            alert("Invalid discount ID")
        } else if (!el[expiryDateDiscount] || (el[expiryDateDiscount].length < 10)) {
            result = false;
            alert("Invalid expiry date")
        } else if (!el[discountPercentage] || (isNaN(el[discountPercentage]))) {
            alert("Incorrect discount (this should be percent discount)");
            result = false;
        } else if (!el[discountDurationMonths] || (isNaN(el[discountDurationMonths]))) {
            alert("Incorrect duration of discount (this should be in months)");
            result = false;
        } else if (!el[itemsAvailable] || (isNaN(el[itemsAvailable]))) {
            alert("Incorrect number of items available (this should be number of items which are allowed to be " +
                "taken for a given discount code )");
            result = false;
        }
    }
    return result; // self.state.content.length > 0;
}

export function validateFormVersion(self) {
    let result = true;
    if (self.gridApi) {
        const el = self.gridApi.getDisplayedRowAtIndex(0).data;
        if ((el[globalsVersions.versionID] === defaultVersion)) {
            result = false;
            alert("Incorrect version ID")
        } else if (!userLib.validateDate(el[globalsVersions.discontinuedDate])) {
            result = false;
            alert("Incorrect discontinued date")
        } else if (!el[globalsVersions.locale]) {
            alert("Incorrect locale");
            result = false;
        } else if (!el[globalsVersions.noticeID]) {
            alert("Incorrect notice ID");
            result = false;
        } else if ((!el[globalsVersions.repeatDays]) ||
            (el[globalsVersions.repeatDays].toString()) !== parseInt(el[globalsVersions.repeatDays]).toString()) {
            alert("Incorrect repeat days");
            result = false;
        } else if (!el[globalsVersions.variant]) {
            alert("Incorrect variant");
            result = false;
        }
    }
    return result; // self.state.content.length > 0;
}

/**
 * Validate email address
 * @param email Email address to validate
 * @returns {boolean} Whether email is valid
 */
function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export function addDistributor(self) {
    if (self.state.distributorId === atukDistributorId) {
        if (self.state.columnDefs[6].field !== globals.inHclPilot) {
            // This is ATUK - add PO related info
            self.state.columnDefs.push({
                headerName: "Clinic ID for PO", field: globals.clinicID,
                width: 180,
            });

            self.state.columnDefs.push({
                headerName: "PO details", field: globals.codePO,
                width: 180,
            });

            self.state.columnDefs.push({
                headerName: "PO expiry", field: globals.expiryDatePO,
                width: 140,
            });

            self.state.columnDefs.push({
                headerName: "User initials", field: globals.userInitials,
                width: 140,
            });
        }
    } else if (userLib.isAdminEditor(self.props.groups) || userLib.isAdminViewer(self.props.groups)) {
        if (self.state.columnDefs[6].field !== globals.inHclPilot) {
            // if (self.state.columnDefs[1].field !== globals.distributorId) {
            // self.state.columnDefs.splice(1, 0, {
            //     headerName: "Distributor ID", field: globals.distributorId,
            //     width: 120,
            // });
            //
            // self.state.columnDefs.splice(2, 0, {
            //     headerName: "Distributor name", field: globals.distributorName,
            //     width: 180,
            // });

            self.state.columnDefs.splice(6, 0, {
                headerName: "HCL pilot", field: globals.inHclPilot,
                width: 120,
                cellStyle: {textAlign: 'center'},
                cellRenderer: function (params) {
                    const input = document.createElement('input');
                    input.type = "checkbox";
                    input.checked = params.value;
                    input.checked = params.value && (params.value !== "false");
                    input.disabled = true;
                    return input;
                }
            });
            self.state.columnDefs.splice(10, 0, {
                headerName: "Omit tamper check", field: globals.omitTamper,
                width: 120,
                cellStyle: {textAlign: 'center'},
                cellRenderer: function (params) {
                    const input = document.createElement('input');
                    input.type = "checkbox";
                    input.checked = params.value;
                    input.checked = params.value && (params.value !== "false");
                    input.disabled = true;
                    return input;
                }
            });

            self.state.columnDefs.splice(11, 0, {
                headerName: "Study", field: globals.study,
                sortable: true, filter: true,
                lockPinned: true,
                width: globals.standardColWidth
            });

            // self.state.columnDefs.splice(11, 0, {
            //     headerName: "Deal owner", field: globals.owner,
            //     sortable: true, filter: true,
            //     lockPinned: true,
            //     width: 120
            // });

            self.state.columnDefs.splice(16, 0, {
                headerName: "User comment", field: globals.userComment,
            });

            self.state.columnDefs.push({
                headerName: "Commercial", field: globals.commercial,
                width: 105,
                cellStyle: {textAlign: 'center'},
                cellRenderer: function (params) {
                    const input = document.createElement('input');
                    input.type = "checkbox";
                    input.checked = params.value;
                    input.checked = params.value && (params.value !== "false");
                    input.disabled = true;
                    return input;
                }
            });

            self.state.columnDefs.push({
                headerName: "Clinic ID for PO", field: globals.clinicID,
                width: 180,
            });

            self.state.columnDefs.push({
                headerName: "PO details", field: globals.codePO,
                width: 180,
            });

            self.state.columnDefs.push({
                headerName: "PO expiry", field: globals.expiryDatePO,
                width: 140,
            });

            self.state.columnDefs.push({
                headerName: "User initials", field: globals.userInitials,
                width: globals.narrowColWidth,
            });

            // self.state.columnDefs.push({
            //     headerName: "Free trial", field: globals.freeTrial,
            //     width: globals.narrowColWidth,
            //     cellStyle: {textAlign: 'center'},
            //     cellRenderer: function (params) {
            //         const input = document.createElement('input');
            //         input.type = "checkbox";
            //         input.checked = params.value;
            //         input.checked = params.value && (params.value !== "false");
            //         input.disabled = true;
            //         return input;
            //     }
            // });

            self.state.columnDefs.push({
                headerName: "Supplier", field: globals.supplier,
                width: globals.standardColWidth,
            });

            self.state.columnDefs.push({
                headerName: "Order cancelled", field: globals.cancelled,
                width: globals.narrowColWidth,
                cellStyle: {textAlign: 'center'},
                cellRenderer: function (params) {
                    const input = document.createElement('input');
                    input.type = "checkbox";
                    input.checked = params.value;
                    input.checked = params.value && (params.value !== "false");
                    input.disabled = true;
                    return input;
                }
            });

            // self.state.columnDefs.push({
            //     headerName: "Cost model", field: globals.costModel,
            //     width: globals.narrowColWidth,
            // });

            self.state.columnDefs.push({
                headerName: "Currency", field: globals.currency,
                width: globals.narrowColWidth,
            });

            // self.state.columnDefs.push({
            //     headerName: "Annual cost", field: globals.annualCost,
            //     width: globals.narrowColWidth,
            // });

            // self.state.columnDefs.push({
            //     headerName: "Monthly cost", field: globals.monthlyCost,
            //     width: globals.narrowColWidth,
            // });

            self.state.columnDefs.push({
                headerName: "Upload", field: globals.upload,
                width: globals.narrowColWidth,
            });
        }
    }
}

/**
 * Extract user info from api data obtained
 * @param user
 */
export function extractUserInfo(user) {
    const object = {};
    let attrib;
    for (attrib of user.Attributes) {
        object[attrib.Name + attrib_suffix] = attrib.Value;
    }
    let field;
    for (field in user) {
        if (field !== "Attributes") {
            object[field] = user[field];
        }
    }
    return object;
}

/**
 * Extract user info from api data obtained
 * @param user
 */
export function extractUserInfoString(user) {
    const object = {};

    object[globals.invitedCloudFollowers] = 'na';

    user.UserCreateDate = user.UserCreateDate && (user.UserCreateDate.includes('"'))
        ? JSON.parse(user.UserCreateDate)
        : user.UserCreateDate;
    user.UserLastModifiedDate = user.UserLastModifiedDate && (user.UserLastModifiedDate.includes('"'))
        ? JSON.parse(user.UserLastModifiedDate)
        : user.UserCreateDate;

    let field;
    for (field in user) {
        if (field !== "Attributes") {
            object[field] = user[field];
        }
    }

    let attrib;
    const jsonAttributes = JSON.parse(user.Attributes);
    for (attrib of jsonAttributes) {
        object[attrib.Name + attrib_suffix] = attrib.Value;
    }
    return object;
}