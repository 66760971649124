import React from "react";
import {Route, Redirect} from "react-router-dom";
import {withTracker} from "../libs/withTracker";
// import {ReCaptcha} from "react-recaptcha-v3";
// import config from "../config";

// eslint-disable-next-line
export default ({component: C, props: cProps, ...rest}) => {
    withTracker(rest.path);
    return (
        <div>
            {/*<ReCaptcha*/}
            {/*    key={cProps.captchaKeyEval}*/}
            {/*    sitekey={config.captchaSiteKey}*/}
            {/*    action={rest.path.length > 1 ? rest.path.substring(1) : "homepage"}*/}
            {/*    verifyCallback={cProps.setCaptchaToken}*/}
            {/*/>*/}
            <Route
                {...rest}
                render={props =>
                    cProps.isAuthenticated || ((rest.path === "/settings/password") && cProps.isNewPasswordRequired)
                        ? <C {...props} {...cProps} />
                        : <Redirect
                            to={`/login?redirect=${props.location.pathname}${props.location
                                .search}`}
                        />}
            />
        </div>
    )
}
