import * as globalsClinic from "./globalDefClinics";
import * as globals from "./globalDef";

/**
 * Shared ag-grid column definition for clinics
 * @type {*[]}
 */
export const columnDefsClinics = [{
    headerName: "Clinic ID", field: globalsClinic.clinicID,
    pinned: 'left',
    width: 140,
}, {
    headerName: "Data access", field: globalsClinic.clinicType,
    width: 105,
    cellRenderer: function (params) {
        if (!params.value) {
            params.value = 'email link'
        }
        return params.value;
    },
}, {
    headerName: "Clinic full name", field: globalsClinic.clinicName,
    width: 250
}, {
    headerName: "Clinic short name", field: globalsClinic.clinicNameShort,
    width: 160
}, {
    headerName: "Admin user email", field: globalsClinic.adminID,
    width: 140
}, {
    headerName: "Admin user name", field: globalsClinic.adminName,
    width: 140
}, {
    headerName: "Finance user email", field: globalsClinic.financeID,
    width: 140
}, {
    headerName: "Finance user name", field: globalsClinic.financeName,
    width: 140
}, {
    headerName: "HCL pilot site", field: globalsClinic.hclPilot,
    width: 120,
    cellStyle: {textAlign: 'center'},
    cellRenderer: function (params) {
        const input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.checked = params.value && (params.value !== "false");
        input.disabled = true;
        return input;
    }
}, {
    headerName: "NHS funded pumps", field: globalsClinic.linkedPumpsNhs,
    width: 140,
    cellStyle: {textAlign: 'center'}
}, {
    headerName: "Clinic linked pumps", field: globalsClinic.linkedPumpsShare,
    width: 140,
    cellStyle: {textAlign: 'center'}
}, {
    headerName: "HCL pilot pumps", field: globalsClinic.hclPumpsNumber,
    width: 140,
    cellStyle: {textAlign: 'center'}
}, {
    headerName: "Pumps allocated to clinic (comma separated)", field: globalsClinic.pumps,
    width: globals.wideCommaSeparated,
}, {
    headerName: "Daily reports", field: globalsClinic.reportDay,
    width: globals.wideCommaSeparated,
}, {
    headerName: "Weekly reports", field: globalsClinic.reportWeek,
    width: globals.wideCommaSeparated,
}, {
    headerName: "Monthly reports", field: globalsClinic.reportMonth,
    width: globals.wideCommaSeparated,
}, {
    headerName: "Clinic suspended", field: globalsClinic.suspended,
    width: 120,
    cellStyle: {textAlign: 'center'},
    cellRenderer: function (params) {
        const input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.checked = params.value && (params.value !== "false");
        input.disabled = true;
        return input;
    }
}, {
    headerName: "Language", field: globalsClinic.localeClinic,
    width: globals.standardColWidth
}, {
    headerName: "Created", field: globalsClinic.established,
    width: globals.standardColWidth
}, {
    headerName: "Last modified", field: globalsClinic.lastModifiedAt,
    width: globals.standardColWidth
},
];

/**
 * Update column definition for data entry
 */
export function setColDefClinicForDataEntry(editting, self) {
// remove items which not entered
    let field;
    let i;

    for (i = self.state.columnDefs.length - 1; i >= 0; i--) {
        field = self.state.columnDefs[i];
        if ((field.field === globalsClinic.lastModifiedAt) ||
            (field.field === globalsClinic.established)) {
            self.state.columnDefs.splice(i, 1);
        } else if (field.field === globalsClinic.localeClinic) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    'EN']
            };
        } else if (field.field === globalsClinic.clinicType) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    'commercial',
                    'research',
                    'email link']
            };
        } else if (field.field === globalsClinic.hclPilot) {
            field.cellStyle.textAlign = 'center';
            field.cellRenderer = function (params) {
                const input = document.createElement('input');
                input.type = "checkbox";
                input.checked = params.value && (params.value !== "false");
                input.addEventListener('click', function (event) {
                    params.value = !params.value;
                    params.node.data[globalsClinic.hclPilot] = params.value;
                });
                return input;
            }
        } else if (field.field === globalsClinic.suspended) {
            field.cellStyle.textAlign = 'center';
            field.cellRenderer = function (params) {
                const input = document.createElement('input');
                input.type = "checkbox";
                input.checked = params.value && (params.value !== "false");
                input.addEventListener('click', function (event) {
                    params.value = !params.value;
                    params.node.data[globalsClinic.suspended] = params.value;
                });
                return input;
            }
        }
        if (editting) {
            if ((field.field !== globalsClinic.clinicID) &&
                (field.field !== globalsClinic.hclPilot) &&
                (field.field !== globalsClinic.suspended)) {
                field.editable = true;
                field.cellStyle = {'white-space': 'normal'};
            }
        } else {
            if ((field.field !== globalsClinic.hclPilot) &&
                (field.field !== globalsClinic.suspended)) {
                field.editable = true;
                field.cellStyle = {'white-space': 'normal'};
            }
        }
    }
}
