import React from "react";
import {Route, Switch} from "react-router-dom";
import Home from "./containers/Home";
import Pumps from "./containers/Pumps";
import Login from "./containers/Login";
import ResetPassword from "./containers/ResetPassword";
import Settings from "./containers/Settings";
import Users from "./containers/Users";
import SentEmails from "./containers/SentEmails";
import ChangePassword from "./containers/ChangePassword";
import NewPump from "./containers/NewPump";
import NotFound from "./containers/NotFound";
import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import PumpDetails from "./containers/PumpDetails";
import NewUser from "./containers/NewUser";
import User from "./containers/User";
import SentEmailsTemp from "./containers/SentEmailsTemp";
import NewClinic from "./containers/NewClinic";
import Clinic from "./containers/Clinic";
import Clinics from "./containers/Clinics";
import NewTrainer from "./containers/NewTrainer";
import Trainer from "./containers/Trainer";
import Trainers from "./containers/Trainers";
import NewDiscount from "./containers/NewDiscount";
import Discount from "./containers/Discount";
import Discounts from "./containers/Discounts";
import Orders from "./containers/Orders";
import Exemptions from "./containers/Exemptions";
import Customers from "./containers/Customers";
import NewVersion from "./containers/NewVersion";
import Version from "./containers/Version";
import Versions from "./containers/Versions";
import Notices from "./containers/Notices";

// eslint-disable-next-line
export default ({childProps}) =>
    <Switch>
        <AppliedRoute path="/" exact component={Home} props={childProps}/>
        <UnauthenticatedRoute path="/login" exact component={Login} props={childProps}/>
        {/*<UnauthenticatedRoute path="/signup" exact component={Signup} props={childProps}/>*/}
        <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps}/>
        <AuthenticatedRoute path="/pumps" exact component={Home} props={childProps}/>
        <AuthenticatedRoute path="/pumps/new" exact component={NewPump} props={childProps}/>
        <AuthenticatedRoute path="/pumps/info/:id" exact component={PumpDetails} props={childProps}/>
        <AuthenticatedRoute path="/pumps/:id" exact component={Pumps} props={childProps}/>
        <AuthenticatedRoute path="/users/new" exact component={NewUser} props={childProps}/>
        <AuthenticatedRoute path="/users/:id" exact component={User} props={childProps}/>
        <AuthenticatedRoute path="/users" exact component={Users} props={childProps}/>
        <AuthenticatedRoute path="/clinics/new" exact component={NewClinic} props={childProps}/>
        <AuthenticatedRoute path="/clinics/:id" exact component={Clinic} props={childProps}/>
        <AuthenticatedRoute path="/clinics" exact component={Clinics} props={childProps}/>
        <AuthenticatedRoute path="/trainers/new" exact component={NewTrainer} props={childProps}/>
        <AuthenticatedRoute path="/trainers/:id" exact component={Trainer} props={childProps}/>
        <AuthenticatedRoute path="/trainers" exact component={Trainers} props={childProps}/>
        <AuthenticatedRoute path="/discounts/new" exact component={NewDiscount} props={childProps}/>
        <AuthenticatedRoute path="/discounts/:id" exact component={Discount} props={childProps}/>
        <AuthenticatedRoute path="/discounts" exact component={Discounts} props={childProps}/>
        <AuthenticatedRoute path="/orders" exact component={Orders} props={childProps}/>
        <AuthenticatedRoute path="/exemptions" exact component={Exemptions} props={childProps}/>
        <AuthenticatedRoute path="/emailstemp" exact component={SentEmailsTemp} props={childProps}/>
        <AuthenticatedRoute path="/emails" exact component={SentEmails} props={childProps}/>
        <AuthenticatedRoute path="/customers" exact component={Customers} props={childProps}/>
        <AuthenticatedRoute path="/versions/new" exact component={NewVersion} props={childProps}/>
        <AuthenticatedRoute path="/versions/:id" exact component={Version} props={childProps}/>
        <AuthenticatedRoute path="/versions" exact component={Versions} props={childProps}/>
        <AuthenticatedRoute path="/notices" exact component={Notices} props={childProps}/>
        <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps}/>
        <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps}/>
        { /* Finally, catch all unmatched routes */}
        <Route component={NotFound}/>
    </Switch>;
