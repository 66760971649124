import * as globalsVersions from "./globalDefVersions";

/**
 * Shared ag-grid column definition for versions
 * @type {*[]}
 */
export const columnDefsVersions = [{
    headerName: "Version ID", field: globalsVersions.versionID,
    pinned: 'left',
    width: 140,
}, {
    headerName: "Locale access", field: globalsVersions.locale,
    width: 80,
}, {
    headerName: "Notice ID", field: globalsVersions.noticeID,
    width: 100
}, {
    headerName: "Filter (regular expression)", field: globalsVersions.filterRegExpr,
    width: 200
}, {
    headerName: "App discontinued date", field: globalsVersions.discontinuedDate,
    width: 140
}, {
    headerName: "Variant", field: globalsVersions.variant,
    width: 90
}, {
    headerName: "Message", field: globalsVersions.message,
    width: 400
}, {
    headerName: "Days to repeat", field: globalsVersions.repeatDays,
    width: 100
}, {
    headerName: "Document name (stored on AWS)", field: globalsVersions.FSNaddress,
    width: 400
},
];

/**
 * Update column definition for data entry
 */
export function setColDefVersionDataEntry(editting, self) {
// remove items which not entered
    let field;
    let i;

    for (i = self.state.columnDefs.length - 1; i >= 0; i--) {
        field = self.state.columnDefs[i];
        if (field.field === globalsVersions.variant) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    'FX', 'HX', 'FX HX']
            };
        }
        if (editting) {
            if ((field.field !== globalsVersions.versionID) &&
                (field.field !== globalsVersions.locale)) {
                field.editable = true;
            }
        } else {
            field.editable = true;
        }
        field.cellStyle = {'white-space': 'normal'};
    }
}
