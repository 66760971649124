/**
 * Attributed suffix
 * @type {string}
 */
export const attrib_suffix = "";

/**
 * Discount code ID
 * @type {string}
 */
export const discountID = "discountCodeId" + attrib_suffix;

/**
 * Discount expiry date
 * @type {string}
 */
export const expiryDateDiscount = "expiryDate" + attrib_suffix;

/**
 * Discount percentage
 * @type {string}
 */
export const discountPercentage = "discountPercentage" + attrib_suffix;

/**
 * Discount duration in months
 * @type {string}
 */
export const discountDurationMonths = "discountDurationMonths" + attrib_suffix;

/**
 * Items available per code
 * @type {string}
 */
export const itemsAvailable = "itemsAvailable" + attrib_suffix;

/**
 * Comment
 * @type {string}
 */
export const discountComment = "discountComment" + attrib_suffix;

/**
 * When record created
 * @type {string}
 */
export const createdAt = "createdAt" + attrib_suffix;

/**
 * When record created
 * @type {string}
 */
export const lastModifiedAt = "lastModifiedAt" + attrib_suffix;