import React, {Component} from 'react';
import {Storage} from "aws-amplify";

export class ReferenceFormatter extends Component {
    downloadURI(uri, name) {
        console.log(uri);
        let link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        // delete link;
    }

    handleClick = (that, e) => {
        e.preventDefault();
        let ref = that.props.value;
        if (ref) {
            ref = ref.substring(7);
            Storage.get(ref, {level: 'public'})
                .then(result => {
                    this.downloadURI(result, ref.replace(/\//g, "_"));
                })
                .catch(err => {
                    alert(err.toString())
                });
        }
    };

    render() {
        let val = this.props.value;
        const that = this;
        if (val) {
            if (val.indexOf('cad') !== -1) {
                val = "CAD log";
            } else if (val.indexOf('debug') !== -1) {
                val = "DEBUG log";
            } else if (val.indexOf('room') !== -1) {
                val = "SQLite DB ";
            }
            if (this.props.value.indexOf('silent') !== -1) {
                val += " silent";
            }
            return (
                <button onClick={this.handleClick.bind(this, that)}>
                    {val}
                </button>
            );
        } else {
            return ('');
        }
    }
}