
const dev = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "camdiab.bug.report"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://77l95j52n3.execute-api.eu-west-1.amazonaws.com/dev"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_dIGZQFQsi",
        APP_CLIENT_ID: "4n3fp74m1qhdtuls8pqcuc232g",
        IDENTITY_POOL_ID: "eu-west-1:81a35e76-141e-4cfe-b953-bc61046490e6"
    },
    captchaSiteKey : "6LdOhcsUAAAAAIUClgNKN8IZ0ni3ZTqpoJtpixo_"
};


const prod = {
    s3: {
        REGION: "eu-west-1",
        BUCKET: "camdiab.bug.report"
    },
    apiGateway: {
        REGION: "eu-west-1",
        URL: "https://cdht1h16bf.execute-api.eu-west-1.amazonaws.com/prod"
    },
    cognito: {
        REGION: "eu-west-1",
        USER_POOL_ID: "eu-west-1_tQY3V8KeR",
        APP_CLIENT_ID: "8b3sldimn56uokcnb27g72vsj",
        IDENTITY_POOL_ID: "eu-west-1:c9ce3952-9ac2-4418-92de-249383408abc"
    },

    captchaSiteKey : "6LdOOcsUAAAAAPMZM7S3WdXceNn03ulZu8cJZT0y"
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;

// eslint-disable-next-line
export default {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
};
