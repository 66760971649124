import React, {Component} from "react";
import {API} from "aws-amplify";
import "./PumpDetails.css";
import * as columnDefinitionDetails from "../libs/columnDefDetails";
import * as globals from "../libs/globalDef";
import {AgGridReact} from "ag-grid-react";
import * as userLib from "../libs/user-lib";


/**
 * Displays pump info details
 */
export default class PumpDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            isDeleting: null,
            pump: null,

            columnDefs: JSON.parse(JSON.stringify(columnDefinitionDetails.columnDefsDetails)),
            columnDefsLogs: columnDefinitionDetails.columnDefsLogs,

            defaultColDef: {
                resizable: true
            },

            style: {
                width: '100%',
                // height: '364px'
                // height: '84px'
                height: '110px'
            },

            styleLogs: {
                width: 4 * globals.standardColWidthLogs + 20,
                // height: '364px'
                // height: '84px'
                // height: '100%'
                height: '550px'
            },

            isAdminEditor: userLib.isAdminEditor(this.props.groups),
            isAdmin: userLib.isAdminEditor(this.props.groups) || userLib.isAdminViewer(this.props.groups),

            rowData: [],
            rowDataLogs: [],
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;
    }

    onGridReady = params => {
        this.gridApi = params.api;
        // this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowData: data});
        };

        updateData(this.state.rowData);
    };

    onGridReadyLogs = params => {
        this.gridApiLogs = params.api;
        // this.gridColumnApiLogs = params.columnApi;

        const updateData = data => {
            this.setState({rowDataLogs: data});
        };

        updateData(this.state.rowDataLogs);

    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.doLayout();
        }

        if (this.gridApiLogs) {
            this.gridApiLogs.doLayout();
        }
    }

    /**
     * Format log records for display in log table
     * @param logRecords Log records to display
     * @returns {Array} Formatted data to be shown in the grid
     */
    processLogsRecords = logRecords => {
        const dataForTable = [];
        // collect up to 50 timestamps
        const maxEntries = 51;
        let i;
        let counter = 0;
        let previousTime = "";
        let oneRow = {};
        let hasElement = false;
        for (i = logRecords.length - 1; (i >= 0) && (counter <= (maxEntries * 3)); i--) {
            counter++;
            const record = logRecords[i].Key;
            if (record) {
                let timeRecord = record.substring(
                    record.lastIndexOf("/") + 1,
                    record.lastIndexOf("___"));

                timeRecord = this.adjustDateField(timeRecord);

                if (timeRecord !== previousTime) {
                    if (previousTime !== "") {
                        // this is a new complete record
                        dataForTable.push(oneRow);
                    }
                    previousTime = timeRecord;
                    oneRow = {[globals.dateTime]: timeRecord};
                    hasElement = false;
                }
                if (record.indexOf('cad') !== -1) {
                    oneRow[globals.cadLog] = record;
                    hasElement = true;
                } else if (record.indexOf('debug') !== -1) {
                    oneRow[globals.debugLog] = record;
                    hasElement = true;
                } else if (record.indexOf('room') !== -1) {
                    oneRow[globals.database] = record;
                    hasElement = true;
                }
            }
        }
        // push last element
        if (hasElement) {
            dataForTable.push(oneRow);
        }
        return dataForTable;
    };

    /**
     * Format date information
     * @param records Records to process
     * @returns {Array} Reformatted records
     */
    processRecords = records => {
        let record;
        for (record of records) {
            try {
                if (record[globals.dateClStarted]) {
                    record[globals.dateClStarted] =
                        this.adjustDate(record[globals.dateClStarted]);
                }
                if (record[globals.dateUsed]) {
                    record[globals.dateUsed] = this.adjustDate(record[globals.dateUsed]);
                }
            } catch (e) {
                // do nothing
            }
        }
        return records;
    };

    /**
     * Reformat date to limit confusion
     * @param date Date to reformat
     * @returns {*} Reformatted date
     */
    adjustDate = date => {
        if (date) {
            return date.replace(/T/g, '  ').slice(0, -8);
        } else {
            return date;
        }
    };

    replaceStringAt(str, index, replacement) {
        return str.substr(0, index) + replacement + str.substr(index + replacement.length);
    }

    adjustDateField = date => {
        if (date) {
            date = date.replace(/__/g, '  ').replace(/_/g, '-');
            const desiredLength = 17;
            if (date.length > desiredLength) {
                date = date.substring(date.length - desiredLength);
            }
            return this.replaceStringAt(date, 14, ':');
        } else {
            return date;
        }
    };

    async componentDidMount() {
        try {
            this.setState({
                distributorId:
                    userLib.getCustomAttribute(this.props.currentUser, 'distributorId')
            });
            if (!this.props.pumpToDuplicate) {
                const pump = await this.getPump();
                this.props.setPumpToDuplicate([pump]);
            }
            const pump = await this.getPumpDetails();
            const records = this.processRecords(pump.recordItems);
            this.setState({rowData: records});
            const logRecords = this.processLogsRecords(pump.logs);
            this.setState({rowDataLogs: logRecords});
        } catch (e) {
            if (e.response && e.response.data && e.response.data.messageUser) {
                this.props.processAlert(e.response.data.messageUser)
            } else {
                alert(e);
            }
        }
        this.props.setUpdateTimeOutListener();
    }

    getPump() {
        return API.get("pumps", `/pumps/${this.props.match.params.id}`, {});
    }

    getPumpDetails() {
        let myInit = {
            queryStringParameters: {isCommercial: this.props.pumpToDuplicate[0].commercial}
        };
        return API.get("pumps", `/pumps/info/${this.props.match.params.id}`, myInit);
    }

    render() {
        return (
            <div className="PumpDetails">
                {this.state.rowData &&
                <div
                    id="pumpGrid"
                    style={this.state.style}
                    className="ag-theme-balham"
                >
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReady}
                        enableCellTextSelection={true}
                        // singleClickEdit={true}
                        // components={this.state.components}
                        rowData={this.state.rowData}
                    />
                </div>}
                {this.state.rowDataLogs
                && (this.state.isAdmin
                    || (this.state.distributorId === '70000')
                    || (this.state.distributorId === '700008888')) // TODO: remove after penetration testing
                && <div
                    id="pumpGridLogs"
                    style={this.state.styleLogs}
                    className="ag-theme-balham log-grid"
                >
                    <AgGridReact
                        columnDefs={this.state.columnDefsLogs}
                        defaultColDef={this.state.defaultColDef}
                        onGridReady={this.onGridReadyLogs}
                        enableCellTextSelection={true}
                        // singleClickEdit={true}
                        // components={this.state.components}
                        /* this is where we provide custom components */
                        // frameworkComponents={this.state.referenceFormatter}
                        rowData={this.state.rowDataLogs}
                    />
                </div>
                }
            </div>
        );
    }
}
