import * as globalsTrainer from "./globalDefTrainers";
import * as globals from "./globalDef";

/**
 * Shared ag-grid column definition for trainers
 * @type {*[]}
 */
export const columnDefsTrainers = [{
    headerName: "Trainer ID", field: globalsTrainer.trainerID,
    pinned: 'left',
    width: 150,
}, {
    headerName: "Trainer name", field: globalsTrainer.trainerName,
    width: 150
}, {
    headerName: "Trainer email", field: globalsTrainer.trainerEmail,
    width: 160
}, {
    headerName: "Trainer clinic (if known)", field: globalsTrainer.trainerClinic,
    width: 160
}, {
    headerName: "Date certified", field: globalsTrainer.trainerDateCertified,
    width: 120,
}, {
    headerName: "Certification method (CDEP or trainer name)", field: globalsTrainer.certificationMethod,
    width: 150

}, {
    headerName: "Closed loop system", field: globalsTrainer.closedLoopSystem,
    width: 110
}, {
    headerName: "Record type", field: globalsTrainer.trainingType,
    width: 110
}, {
    headerName: "Language", field: globalsTrainer.locale,
    width: globals.standardColWidth
}, {
    headerName: "Trainer suspended", field: globalsTrainer.suspended,
    width: globals.standardColWidth
}, {
    headerName: "Created", field: globalsTrainer.createdAt,
    width: globals.standardColWidth
}, {
    headerName: "Last modified", field: globalsTrainer.lastModifiedAt,
    width: globals.standardColWidth
},
];

/**
 * Update column definition for data entry
 */
export function setColDefTrainerForDataEntry(editting, self) {
// remove items which not entered
    let field;
    let i;

    for (i = self.state.columnDefs.length - 1; i >= 0; i--) {
        field = self.state.columnDefs[i];
        if ((field.field === globalsTrainer.lastModifiedAt) ||
            (field.field === globalsTrainer.createdAt)) {
            self.state.columnDefs.splice(i, 1);
        } else if (field.field === globalsTrainer.suspended) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    "false",
                    "true"]
            };
        } else if (field.field === globalsTrainer.closedLoopSystem) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    "FX",
                    "HX"]
            };
        } else if (field.field === globalsTrainer.trainingType) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    "professional",
                    "user"]
            };
        } else if (field.field === globalsTrainer.locale) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    'EN']
            };
        }
        if (editting) {
            if (field.field !== globalsTrainer.trainerID) {
                field.editable = true;
            }
        } else {
            field.editable = true;
        }
    }
}
