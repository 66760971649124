/**
 * Attributed suffix
 * @type {string}
 */
export const attrib_suffix = "";

/**
 * Version ID
 * @type {string}
 */
export const versionID = "versionID" + attrib_suffix;

/**
 * Locale for the version notice
 * @type {string}
 */
export const locale = "locale" + attrib_suffix;

/**
 * Notice ID (can be same for different locales)
 * @type {string}
 */
export const noticeID = "noticeID" + attrib_suffix;

/**
 * Filter using regular expression
 * @type {string}
 */
export const filterRegExpr = "filterRegExpr" + attrib_suffix;

/**
 * Message to be shown to the user
 * @type {string}
 */
export const message = "message" + attrib_suffix;

/**
 * Number of days the message should be shown
 * @type {Number}
 */
export const repeatDays = "repeatDays" + attrib_suffix;

/**
 * For which variant the message should be shown
 * @type {string}
 */
export const variant = "variant";

/**
 * Web address of Field safety notice
 * @type {string}
 */
export const FSNaddress = "FSNaddress";

/**
 * Date when this specific version is to be discontinued
 * @type {string}
 */
export const discontinuedDate = "discontinuedDate";

