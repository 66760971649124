import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import "./NewPump.css";
import {API} from "aws-amplify";
import * as globals from "../libs/globalDef";
import * as columnDefinition from "../libs/columnDef";
import * as userLib from "../libs/user-lib";
import {AgGridReact} from "ag-grid-react";
import {addDistributor} from "../libs/user-lib";
import {validateForm} from "../libs/user-lib";

export default class NewPump extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            selectedPumps: [],

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefinition.columnDefs)),

            defaultColDef: {
                resizable: true
            },

            style: {
                width: '100%',
                // height: '364px'
                height: '84px'
            },
            components: {datePicker: this.getDatePicker()},
            rowData: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // add distributor fields
        addDistributor(this);
        // update column definition
        columnDefinition.setColDefForDataEntry(false, this);
    }

    recalculateRemainingDays = (params) => {
        const element = params.api.getDisplayedRowAtIndex(0).data;
        element[globals.remainingDays] = userLib.calculateDaysLeft(element[globals.startDate],
            element[globals.expiryDate]);
        params.api.refreshCells();
    };

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowData: data});
        };

        updateData(this.state.rowData);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.doLayout();
        }
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }
        this.props.setUpdateTimeOutListener();

        if (this.props.pumpToDuplicate && this.props.pumpToDuplicate.length > 0) {
            this.setState({rowData: [this.props.pumpToDuplicate[0]]});
            this.setState({isAdminEditor: userLib.isAdminEditor(this.props.groups)})
        } else {
            const obj = [{[globals.pumpSN]: userLib.defaultSN}];
            obj[0][globals.remainingDays] = 0;
            this.setState({rowData: obj});
        }
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.gridApi.stopEditing();
        if (validateForm(this)) {
            this.setState({isLoading: true});

            try {
                const element = this.gridApi.getDisplayedRowAtIndex(0).data;
                if (element[globals.commercial] && (typeof element[globals.commercial] === 'string')) {
                    element[globals.commercial] = element[globals.commercial].toLowerCase() === "true";
                }
                if (element[globals.clinicID]) {
                    element[globals.clinicID] = element[globals.clinicID].trim();
                }
                // reload data
                this.props.setRowData([]);

                await this.createPump(element);
                this.props.history.push("/pumps");
            } catch (e) {
                if (e.response && e.response.data && e.response.data.messageUser) {
                    this.props.processAlert(e.response.data.messageUser)
                } else {
                    alert(e);
                }
                this.setState({isLoading: false});
            }
        }
    };

    createPump(pump) {
        let attrib;
        for (attrib in pump) {
            if (Object.prototype.hasOwnProperty.call(pump, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }
        return API.post("pumps", "/pumps", {
            body: pump
        });
    }

    render() {
        return (
            <div className="NewPump">
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            components={this.state.components}
                            rowData={this.state.rowData}
                        />
                    </div>

                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        // disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Create"
                        loadingText="Creating…"
                    />
                </form>
            </div>
        );
    }

    getDatePicker() {
        function Datepicker() {
        }

        Datepicker.prototype.init = function (params) {
            this.eInput = document.createElement("input");
            this.eInput.value = params.value;
            window.$(this.eInput).datepicker({dateFormat: "dd/mm/yy"});
        };
        Datepicker.prototype.getGui = function () {
            return this.eInput;
        };
        Datepicker.prototype.afterGuiAttached = function () {
            this.eInput.focus();
            this.eInput.select();
        };
        Datepicker.prototype.getValue = function () {
            return this.eInput.value;
        };
        Datepicker.prototype.destroy = function () {
        };
        Datepicker.prototype.isPopup = function () {
            return false;
        };
        return Datepicker;
    }
}
