import * as globalsDiscount from "./globalDefDiscounts";
import * as globals from "./globalDef";

/**
 * Shared ag-grid column definition for discount codes
 * @type {*[]}
 */
export const columnDefsDiscounts = [{
    headerName: "Discount code", field: globalsDiscount.discountID,
    pinned: 'left',
    width: 140,
}, {
    headerName: "Expiry date", field: globalsDiscount.expiryDateDiscount,
    width: 130
}, {
    headerName: "Discount (%)", field: globalsDiscount.discountPercentage,
    width: 120
}, {
    headerName: "Discount duration (months)", field: globalsDiscount.discountDurationMonths,
    width: 120
}, {
    headerName: "Discounted items left", field: globalsDiscount.itemsAvailable,
    width: 120,
}, {
    headerName: "Comment", field: globalsDiscount.discountComment,
    width: 230
}, {
    headerName: "Created", field: globalsDiscount.createdAt,
    width: globals.standardColWidth
}, {
    headerName: "Last modified", field: globalsDiscount.lastModifiedAt,
    width: globals.standardColWidth
},
];

/**
 * Update column definition for data entry
 */
export function setColDefDiscountForDataEntry(editting, self) {
// remove items which not entered
    let field;
    let i;

    for (i = self.state.columnDefs.length - 1; i >= 0; i--) {
        field = self.state.columnDefs[i];
        if ((field.field === globalsDiscount.lastModifiedAt) ||
            (field.field === globalsDiscount.createdAt)) {
            self.state.columnDefs.splice(i, 1);
        }
        if (editting) {
            if (field.field !== globalsDiscount.discountID) {
                field.editable = true;
            }
        } else {
            field.editable = true;
        }
    }
}
