import React from "react";
import {Route, Redirect} from "react-router-dom";
import {withTracker} from "../libs/withTracker";
// import {ReCaptcha} from "react-recaptcha-v3";
// import config from "../config";

function querystring(name, url = window.location.href) {
    name = name.replace(/[[]]/g, "\\$&");

    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)", "i");
    const results = regex.exec(url);

    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }

    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

// eslint-disable-next-line
export default ({component: C, props: cProps, ...rest}) => {
    const redirect = querystring("redirect");
    withTracker(rest.path);
    return (
        <div>
            {/*<ReCaptcha*/}
            {/*    key={cProps.captchaKeyEval}*/}
            {/*    sitekey={config.captchaSiteKey}*/}
            {/*    action={rest.path.length > 1 ? rest.path.substring(1) : "homepage"}*/}
            {/*    verifyCallback={cProps.setCaptchaToken}*/}
            {/*/>*/}
            <Route
                {...rest}
                render={props =>
                    !cProps.isAuthenticated
                        ? <C {...props} {...cProps} />
                        : <Redirect
                            to={redirect === "" || redirect === null ? "/pumps" : redirect}
                        />}
            />
        </div>
    );
};
