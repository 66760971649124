/**
 * Attributed suffix
 * @type {string}
 */
export const attrib_suffix = "";

/**
 * Sub UUID
 * @type {string}
 */
export const subId = "sub" + attrib_suffix;

/**
 * User email
 * @type {string}
 */
export const emailId = "email" + attrib_suffix;

/**
 * Username (as email)
 * @type {string}
 */
export const usernameId = "Username" + attrib_suffix;

/**
 * Whether email has been verified
 * @type {string}
 */
export const email_verified = "email_verified" + attrib_suffix;

/**
 * User name attribute
 * @type {string}
 */
export const userName = "name" + attrib_suffix;

/**
 * Distributor ID
 * @type {string}
 */
export const distributorId = "custom:distributorId" + attrib_suffix;

/**
 * User type field (can be, "Admin Editor", "Admin Viewer", "Editor", "")
 * @type {string}
 */
export const userTypeField = "custom:user_type" + attrib_suffix;

/**
 * Clinic ID field
 * @type {string}
 */
export const clinicField = "custom:clinic" + attrib_suffix;

/**
 * Trainer field (can be, "Editor", "Viewer", "")
 * @type {string}
 */
export const trainerField = "custom:trainer" + attrib_suffix;

/**
 * Training status field (can be, "Editor", "Viewer", "")
 * @type {string}
 */
export const trainingStatusField = "custom:training_status" + attrib_suffix;

/**
 * Sending emails field (can be, "Editor", "Viewer", "")
 * @type {string}
 */
export const sendingEmailsField = "custom:send_emails" + attrib_suffix;

/**
 * CamAPS users field (can be, "Editor", "Viewer", "")
 * @type {string}
 */
export const usersField = "custom:camaps_users" + attrib_suffix;

/**
 * CamAPS orders field (can be, "Viewer", "")
 * @type {string}
 */
export const orderField = "custom:order" + attrib_suffix;

/**
 * VAT exceptions field (can be, "Viewer", "")
 * @type {string}
 */
export const exemptionField = "custom:exemption" + attrib_suffix;

/**
 * Discount codes field (can be, "Viewer", "")
 * @type {string}
 */
export const discountField = "custom:discount" + attrib_suffix;

/**
 * Version message (can be "Editor", "Viewer", "")
 * @type {string}
 */
export const versionField = "custom:version" + attrib_suffix;

/**
 * Notices that customer read message codes field (can be, "Viewer", "")
 * @type {string}
 */
export const noticesField = "custom:notice" + attrib_suffix;

/**
 * Customers information field (can be, "Viewer", "")
 * @type {string}
 */
export const customersField = "custom:customer" + attrib_suffix;

/**
 * Whether user is enabled
 * @type {string}
 */
export const enabledUser = "Enabled";

/**
 * Whether password should be reset
 * @type {string}
 */
export const resetPasswordHeading = "Password";

/**
 * Date when user created
 * @type {string}
 */
export const dateCreatedUser = "UserCreateDate";

/**
 * Date when user last modified
 * @type {string}
 */
export const lastModifiedDateUser = "UserLastModifiedDate";

/**
 * User status (i.e. confirmed)
 * @type {string}
 */
export const userStatus = "UserStatus";

/**
 * User type "Admin Editor"
 * @type {string}
 */
export const userTypeAdminEditor = "Admin Editor";

/**
 * User type "Admin Viewer"
 * @type {string}
 */
export const userTypeAdminViewer = "Admin Viewer";

/**
 * User type "Editor"
 * @type {string}
 */
export const userTypeEditor = "Editor";

/**
 * User type "Viewer"
 * @type {string}
 */
export const userTypeViewer = "Viewer";

/**
 * Reset password keyword
 * @type {string}
 */
export const resetPassword = "Reset";