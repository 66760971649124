import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import "./NewVersion.css";
import {API} from "aws-amplify";
import * as columnDefsVersions from "../libs/columnDefVersions";
import {AgGridReact} from "ag-grid-react";
import * as userLib from "../libs/user-lib";
import {validateFormVersion} from "../libs/user-lib";
import * as globalsVersion from "../libs/globalDefVersions";

export default class NewVersion extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            selectedVersions: [],

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsVersions.columnDefsVersions)),

            defaultColDef: {
                resizable: true
            },
            style: {
                width: '100%',
                // height: '364px'
                height: '110px'
            },

            isEditor: userLib.isCustomEditor(this.props.currentUser, 'version'),
            rowData: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // update column definition
        columnDefsVersions.setColDefVersionDataEntry(false, this);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowData: data});
        };

        updateData(this.state.rowData);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.doLayout();
        }
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }
        this.props.setUpdateTimeOutListener();
        this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'version')});

        if (this.props.versionToDuplicate && this.props.versionToDuplicate.length > 0) {
            this.setState({rowData: [this.props.versionToDuplicate[0]]});
        } else {
            const obj = [{
                [globalsVersion.versionID]: userLib.defaultVersion,
                [globalsVersion.locale]: userLib.defaultVersion
            }];
            this.setState({rowData: obj});
        }
    }

    createVersion(version) {
        let attrib;
        for (attrib in version) {
            if (Object.prototype.hasOwnProperty.call(version, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }
        // add captcha token
        version.captchaToken = this.props.captchaToken;

        return API.post("pumps", "/versions", {
            body: version
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.gridApi.stopEditing();
        if (validateFormVersion(this)) {
            this.setState({isLoading: true});
            let element;
            try {
                element = this.gridApi.getDisplayedRowAtIndex(0).data;
                await this.createVersion(element);
                // reload data
                this.props.setRowDataVersions([]);
                this.props.history.push("/versions");
            } catch (e) {
                if (e.response && e.response.data && e.response.data.messageUser) {
                    this.props.processAlert(e.response.data.messageUser)
                } else {
                    alert(e);
                }
                this.setState({isLoading: false});
            }
            this.props.getNewCaptchaToken();
        }
    };

    render() {
        return (
            <div className="NewVersion">
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            getRowHeight={this.state.getRowHeight}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            rowData={this.state.rowData}
                            // headerHeight={58}
                        />
                    </div>

                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        // disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Create"
                        loadingText="Creating…"
                    />
                </form>
            </div>
        );
    }
}
