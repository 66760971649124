/**
 * Attributed suffix
 * @type {string}
 */
export const attrib_suffix = "";

/**
 * Customer email
 * @type {string}
 */
export const orderID = "customerEmail" + attrib_suffix;

/**
 * Time of order
 * @type {string}
 */
export const orderTime = "orderTime" + attrib_suffix;

/**
 * First name
 * @type {string}
 */
export const firstName = "firstName" + attrib_suffix;

/**
 * Last name
 * @type {string}
 */
export const lastName = "lastName" + attrib_suffix;

/**
 * Pump serial number
 * @type {string}
 */
export const pumpSN = "pumpSN" + attrib_suffix;

/**
 * Training clinic
 * @type {string}
 */
export const trainingClinic = "trainingClinic" + attrib_suffix;

/**
 * Subscription model
 * @type {string}
 */
export const subscription = "subscription" + attrib_suffix;

/**
 * Comment with costline
 * @type {string}
 */
export const comment = "comment" + attrib_suffix;

/**
 * Monthly cost
 * @type {string}
 */
export const cost = "cost" + attrib_suffix;

/**
 * Currency of subscription
 * @type {string}
 */
export const currency = "currency" + attrib_suffix;


/**
 * Whether invoice sent
 * @type {string}
 */
export const invoiceSent = "invoiceSent" + attrib_suffix;

/**
 * Exempt from VAT
 * @type {string}
 */
export const exemptFromVat = "exemptFromVat" + attrib_suffix;

/**
 * Discount code
 * @type {string}
 */
export const discountCode = "discountCode" + attrib_suffix;
/**
 * Discount duration in months
 * @type {string}
 */
export const discountDurationMonths = "discountDurationMonths" + attrib_suffix;
/**
 * Subscription model
 * @type {string}
 */
export const discountPercentage = "discountPercentage" + attrib_suffix;

/**
 * Discount percentage
 * @type {string}
 */
export const heardFrom = "heardFrom" + attrib_suffix;

/**
 * User locale
 * @type {string}
 */
export const locale = "locale" + attrib_suffix;

/**
 * When record created
 * @type {string}
 */
export const createdAt = "createdAt" + attrib_suffix;

/**
 * When record created
 * @type {string}
 */
export const lastModifiedAt = "lastModifiedAt" + attrib_suffix;