import * as globalsUser from "./globalDefUsers";
import * as globals from "./globalDef";

/**
 * Shared ag-grid column definition for customers
 * @type {*[]}
 */
export const columnDefsCustomers = [{
    headerName: "User email", field: globalsUser.emailId,
    pinned: 'left',
    width: 240,
// }, {
//     headerName: "Email verified", field: globalsUser.email_verified,
//     width: 85,
}, {
    headerName: "Name", field: globalsUser.userName,
    width: 160
}, {
    headerName: "User enabled", field: globalsUser.enabledUser,
    width: 86,
    cellClassRules: {
        'rag-red': function (params) {
            return (!params.value) || params.value === "false"
        }
    }
}, {
    headerName: "User status", field: globalsUser.userStatus,
    width: 120
}, {
    headerName: "Distributor name", field: globals.distributorName,
    width: 200
}, {
    headerName: "Created", field: globalsUser.dateCreatedUser,
    width: 170
}, {
    headerName: "Last modified", field: globalsUser.lastModifiedDateUser,
    width: 170
}, {
    headerName: "Pump SN", field: globals.pumpSN,
    width: 120
}, {
    headerName: "Type", field: globals.commercial,
    width: 90
}, {
    headerName: "App version", field: globals.appVersion,
    width: 120
}, {
    headerName: "HCL pilot", field: globals.inHclPilot,
    width: 105,
    cellStyle: {textAlign: 'center'},
    cellRenderer: function (params) {
        const input = document.createElement('input');
        input.type = "checkbox";
        input.checked = params.value;
        input.checked = params.value && (params.value !== "false");
        input.disabled = true;
        return input;
    }
// }, {
//     headerName: "Deal owner", field: globals.owner,
//     width: 120
}, {
    headerName: "Date used", field: globals.dateUsed,
    width: 170
}, {
    headerName: "Clinic ID", field: globals.clinicID,
    width: 90
}, {
    headerName: "PO details", field: globals.codePO,
    width: 90
}, {
    headerName: "PO expiry", field: globals.expiryDatePO,
    width: 90
}, {
    headerName: "Locale", field: globals.locale,
    width: 90
}, {
    headerName: "Country", field: globals.country,
    width: 150
}, {
    headerName: "Age category", field: globals.ageCategory,
    width: globals.standardColWidth
}, {
    headerName: "Year of birth", field: globals.dateOfBirth,
    width: globals.standardColWidth
},{
    headerName: "Active followers", field: globals.invitedCloudFollowers,
    width: globals.standardColWidth
}, {
    headerName: "User hash", field: globals.userIdHash,
    width: globals.standardColWidth
}, {
    headerName: "Phone model", field: globals.phoneModel,
    width: 450
}, {
    headerName: "CGM", field: globals.transmitter,
    width: 5 * globals.standardColWidth
},
];

