import React, {Component} from "react";
import LoaderButton from "../components/LoaderButton";
import "./NewClinic.css";
import {API} from "aws-amplify";
import * as columnDefsClinics from "../libs/columnDefClinics";
import {AgGridReact} from "ag-grid-react";
import * as userLib from "../libs/user-lib";
import {validateFormClinic} from "../libs/user-lib";
import * as globalsClinic from "../libs/globalDefClinics";

export default class NewClinic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            selectedClinics: [],

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsClinics.columnDefsClinics)),

            defaultColDef: {
                resizable: true
            },

            getRowHeight: function (params) {
                const pumpsLen = params.data.pumps ? params.data.pumps.length : 0;
                const dayLen = params.data.reportDay ? params.data.reportDay.length : 0;
                const weekLen = params.data.reportWeek ? params.data.reportWeek.length : 0;
                const monthLen = params.data.reportMonth ? params.data.reportMonth.length : 0;
                const maxLen = Math.max(pumpsLen, dayLen, weekLen, monthLen);
                return 28 * (Math.floor(maxLen / 26.1) + 1);
            },
            style: {
                width: '100%',
                // height: '364px'
                height: '284px'
            },

            isEditor: userLib.isCustomEditor(this.props.currentUser, 'clinic'),
            rowData: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // update column definition
        columnDefsClinics.setColDefClinicForDataEntry(false, this);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowData: data});
        };

        updateData(this.state.rowData);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.doLayout();
        }
    }

    componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }
        this.props.setUpdateTimeOutListener();
        this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'clinic')});

        if (this.props.clinicToDuplicate && this.props.clinicToDuplicate.length > 0) {
            try {
                this.reformatStringArrays(this.props.clinicToDuplicate[0]);
            } catch (e) {
            }
            this.setState({rowData: [this.props.clinicToDuplicate[0]]});
        } else {
            const obj = [{[globalsClinic.clinicID]: userLib.defaultClinic}];
            this.setState({rowData: obj});
        }
    }

    reformatStringArrays(clinic) {
        clinic.pumps = clinic.pumps.join(', ');
        clinic.reportDay = clinic.reportDay.join(', ');
        clinic.reportWeek = clinic.reportWeek.join(', ');
        clinic.reportMonth = clinic.reportMonth.join(', ');
    }

    createClinic(clinic) {
        let attrib;
        for (attrib in clinic) {
            if (Object.prototype.hasOwnProperty.call(clinic, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }
        if (clinic.pumps) {
            clinic.pumps = clinic.pumps.replace(/\s/g, '').split(',');
        } else {
            clinic.pumps = [];
        }
        if (clinic.reportDay) {
            clinic.reportDay = clinic.reportDay.replace(/\s/g, '').split(',');
        } else {
            clinic.reportDay = [];
        }
        if (clinic.reportWeek) {
            clinic.reportWeek = clinic.reportWeek.replace(/\s/g, '').split(',');
        } else {
            clinic.reportWeek = [];
        }
        if (clinic.reportMonth) {
            clinic.reportMonth = clinic.reportMonth.replace(/\s/g, '').split(',');
        } else {
            clinic.reportMonth = [];
        }
        if (!clinic.locale) {
            clinic.locale = 'EN';
        }
        clinic.adminID = clinic.adminID.toLowerCase();
        clinic.financeID = clinic.financeID ? clinic.financeID.toLowerCase() : null;
        return API.post("pumps", "/clinics", {
            body: clinic
        });
    }

    handleSubmit = async event => {
        event.preventDefault();

        this.gridApi.stopEditing();
        if (validateFormClinic(this)) {
            this.setState({isLoading: true});
            let element;
            try {
                element = this.gridApi.getDisplayedRowAtIndex(0).data;
                await this.createClinic(element);
                this.reformatStringArrays(element);
                // reload data
                this.props.setRowDataClinics([]);
                this.props.history.push("/clinics");
            } catch (e) {
                this.reformatStringArrays(element);
                if (e.response && e.response.data && e.response.data.messageUser) {
                    this.props.processAlert(e.response.data.messageUser)
                } else {
                    alert(e);
                }
                this.setState({isLoading: false});
            }
        }
    };

    render() {
        return (
            <div className="NewClinic">
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            getRowHeight={this.state.getRowHeight}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            rowData={this.state.rowData}
                            headerHeight={58}
                        />
                    </div>

                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        // disabled={!this.validateForm()}
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Create"
                        loadingText="Creating…"
                    />
                </form>
            </div>
        );
    }
}
