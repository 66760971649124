/**
 * Attributed suffix
 * @type {string}
 */
export const attrib_suffix = "";

/**
 * Clinic ID
 * @type {string}
 */
export const clinicID = "clinicID" + attrib_suffix;

/**
 * Clinic type (commercial, research or link)
 * @type {string}
 */
export const clinicType = "clinicType" + attrib_suffix;

/**
 * Administrator email
 * @type {string}
 */
export const adminID = "adminID" + attrib_suffix;

/**
 * Administrator name
 * @type {string}
 */
export const adminName = "adminName" + attrib_suffix;

/**
 * Administrator email
 * @type {string}
 */
export const financeID = "financeID" + attrib_suffix;

/**
 * Administrator name
 * @type {string}
 */
export const financeName = "financeName" + attrib_suffix;

/**
 * Full clinic name
 * @type {string}
 */
export const clinicName = "clinicName" + attrib_suffix;

/**
 * Short clinic name
 * @type {string}
 */
export const clinicNameShort = "clinicNameShort" + attrib_suffix;

/**
 * When established
 * @type {string}
 */
export const established = "established" + attrib_suffix;

/**
 * Whether the site participates in HCL pilot
 * @type {string}
 */
export const hclPilot = "hclPilot" + attrib_suffix;

/**
 * Number of linked pumps with POs and end date beyond current date
 * @type {string}
 */
export const linkedPumpsNhs = "linkedPumpsNhs" + attrib_suffix;

/**
 * Number of pumps shared with a clinic
 * @type {string}
 */
export const linkedPumpsShare = "linkedPumpsShare" + attrib_suffix;

/**
 * Number of pumps in HCL pilot in a clinic
 * @type {string}
 */
export const hclPumpsNumber = "hclPumpsNumber" + attrib_suffix;

/**
 * Array of pumps belonging to the clinic
 * @type {string}
 */
export const pumps = "pumps" + attrib_suffix;

/**
 * Email addresses of users to receive daily reports
 * @type {string}
 */
export const reportDay = "reportDay" + attrib_suffix;

/**
 * Email addresses of users to receive monthly reports
 * @type {string}
 */
export const reportMonth = "reportMonth" + attrib_suffix;

/**
 * Email addresses of users to receive weekly reports
 * @type {string}
 */
export const reportWeek = "reportWeek" + attrib_suffix;

/**
 * Whether clinic is suspended
 * @type {string}
 */
export const suspended = "suspended" + attrib_suffix;

/**
 * Locale for the clinic
 * @type {string}
 */
export const localeClinic = "locale";

/**
 * Time when last modified
 * @type {string}
 */
export const lastModifiedAt = "lastModifiedAt" + attrib_suffix;