// X-browser print local datetime w/ yyyy-MM-dd HH:mm:ss TZInfo format
//    e.g. 2014-11-20 03:16:26
// @author  noromanba   http://flavors.me/noromanba
// @license CC0 Univ PD http://creativecommons.org/publicdomain/zero/1.0

// c.f.
// https://gist.github.com/noromanba/6736822
// http://let.hatelabo.jp/noromanba/let/hJmcrJfO94ka/rev/hJmcruLM2p0H
// http://let.hatelabo.jp/noromanba/let/hJmcrJfO94ka
export const toDateTimeInfoString = (function (epoch) {
    // non-standard JS 1.6, Fx only; c.f.
    // http://compatibility.shwups-cms.ch/en/home/?&&&property=Date.prototype.toLocaleFormat
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleFormat
    if (Date.prototype.toLocaleFormat) {
        return function () {
            return new Date(epoch).toLocaleFormat('%Y-%m-%d %T');
        };
    }

    // Fx not supported yet; c.f.
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString
    if (Date.prototype.toLocaleDateString) {
        return function (epoch) {
            const now = new Date(epoch);
            return now.toLocaleDateString('ja', {
                year:  'numeric',
                month: '2-digit',
                day:   '2-digit'
            }).replace(/\//g, '-') + ' ' +  now.toTimeString();
        };
    }

    // Safari, almost browsers and mobile
    return function (epoch) {
        const now = new Date(epoch);
        return [
            now.getFullYear(),
            '-', ('0' + (now.getMonth() + 1)).slice(-2),
            '-', ('0' + now.getDate()).slice(-2),
            ' ', now.toTimeString()
        ].join('');
    };
})();