import React, {Component} from "react";
import {API} from "aws-amplify";
import {Button, Glyphicon, PageHeader} from "react-bootstrap";
import "./Trainers.css";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import * as userLib from "../libs/user-lib";
import {columnDefsTrainers} from "../libs/columnDefTrainers";
import {trainerEmail, trainerID} from "../libs/globalDefTrainers";
import {nl} from "./SentEmailsTemp";
import {saveAs} from "file-saver";

export default class Trainers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            fileContent: [],
            users: [],
            selectedTrainers: [],
            isEditor: userLib.isCustomEditor(this.props.currentUser, 'trainer'),
            isViewer: userLib.isCustomViewer(this.props.currentUser, 'trainer'),

            columnDefs: columnDefsTrainers,

            defaultColDef: {
                sortable: true,
                filter: true,
                lockPinned: true,
                resizable: true
            },
            style: {
                width: '100%',
                height: '644px'
                // height: '364px'
                // height: '2884px'
            },
            rowSelection: "single",
            paginationPageSize: 100,
            paginationNumberFormatter: function (params) {
                return params.value.toLocaleString();
            }
        };

        this.state.columnDefs[0].checkboxSelection = true;
        const self = this;
        this.state.columnDefs[0].cellRenderer = function (params) {
            let link = document.createElement('a');
            link.href = '#';
            link.innerText = params.value;
            link.addEventListener('click', (e) => {
                e.preventDefault();
                if (self.state.isEditor) {
                    self.props.setTrainerToDuplicate(null);
                    self.props.history.push('/trainers/' + params.data[trainerID]);
                }
            });

            return link;
        };
    }

    onGridReady = params => {
        this.gridApi = params.api;
        // this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.props.setRowDataTrainers(data);
            params.api.paginationGoToPage(0);
        };

        updateData(this.props.rowDataTrainers);
        const value = this.props.searchBoxValue;
        const box = document.getElementById('filter-text-box');
        if (box) {
            box.value = value;
        }
        params.api.setQuickFilter(value);

        const valuePageSize = this.props.pageSize;
        document.getElementById("page-size").value = valuePageSize;
        this.gridApi.paginationSetPageSize(Number(valuePageSize));
    };


    onPageSizeChanged() {
        // const pxPerRow = 28;
        const value = document.getElementById("page-size").value;
        this.props.setPageSize(value);
        this.gridApi.paginationSetPageSize(Number(value));

        // const newHeight = ((Number(value) + 3) * pxPerRow).toString() + "px";
        // this.setHeight(newHeight);
    }

    onSelectionChanged() {
        this.setState({selectedTrainers: this.gridApi.getSelectedRows()});
    }

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.doLayout();
        }
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'trainer')});
        this.setState({isViewer: userLib.isCustomViewer(this.props.currentUser, 'trainer')});

        if (this.props.rowDataTrainers.length === 0) {
            try {
                const trainers = await this.clinics("");
                let tempResult = trainers;
                while (tempResult.LastEvaluatedKey) {
                    tempResult = await this.clinics(tempResult.LastEvaluatedKey);
                    trainers.Items = trainers.Items.concat(tempResult.Items);
                }
                this.props.setRowDataTrainers(trainers.Items);
                for (let i = 0; i < trainers.Items.length; i++) {
                    const item = trainers.Items[i];
                    if (!item.hasOwnProperty('closedLoopSystem')) {
                        item.closedLoopSystem = "FX";
                    }
                }
                this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'trainer')});
                this.setState({isViewer: userLib.isCustomViewer(this.props.currentUser, 'trainer')});
            } catch (e) {
                if (e.response && e.response.data && e.response.data.messageUser) {
                    this.props.processAlert(e.response.data.messageUser)
                } else {
                    alert(e);
                }
            }
        } else {
            this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'trainer')});
            this.setState({isViewer: userLib.isCustomViewer(this.props.currentUser, 'trainer')});

        }
        this.props.setUpdateTimeOutListener();
        this.setState({isLoading: false});
    }

    clinics(lastEvaluatedKey) {
        let myInit = {
            queryStringParameters: {}
        };
        if (lastEvaluatedKey && ("" !== lastEvaluatedKey.trainerID)) {
            myInit.queryStringParameters.ExclusiveStartKey = lastEvaluatedKey.trainerID;
        }
        return API.get("pumps", "/trainers", myInit);
    }

    onFilterTextBoxChanged() {
        if (this.gridApi) {
            const value = document.getElementById('filter-text-box').value;
            this.props.setSearchBox(value);
            this.gridApi.setQuickFilter(value);
        }
    }

    onAddTrainer() {
        this.props.setTrainerToDuplicate(null);
        this.props.history.push('/trainers/new');
    }

    onDuplicateTrainer() {
        this.props.setTrainerToDuplicate(this.state.selectedTrainers);
        this.props.history.push('/trainers/new');
    }

    addOutput(line) {
        this.state.fileContent.push(line);
        this.setState({fileContent: this.state.fileContent});
    }

    addOutputLine(line) {
        this.addOutput(line + nl);
    }

    onBtEmails() {
        const size = this.gridApi.getDisplayedRowCount();
        const emails = [];
        for (let i = 0; i < size; i++) {
            const node = this.gridApi.getDisplayedRowAtIndex(i);
            for (let attr in node.data) {
                if (attr === trainerEmail) {
                    emails.push(node.data[attr]);
                }
            }
        }
        try {
            let email;
            let counter = 0;
            const split = 49;
            for (email of emails) {
                this.addOutput(email + ";");
                counter++;
                if (counter >= split) {
                    counter = 0;
                    this.addOutputLine("");
                    this.addOutputLine("");
                    this.addOutputLine("");
                }
            }
            this.saveFile("All CamAPS FX Trainers.txt");
            // alert("Email addresses of CamAPS FX Trainers have been split into " +
            //     "blocks of 50. These blocks can be pasted into BCC field when " +
            //     "sending bulk emails to CamAPS FX trainers.");
        } catch (e) {
            if (e.response && e.response.data && e.response.data.messageUser) {
                this.props.processAlert(e.response.data.messageUser)
            } else {
                alert(e);
            }
        }
    }

    saveFile(outFileName) {
        const blob = new Blob(this.state.fileContent, {type: "text/plain;charset=utf-8"});
        saveAs(blob, outFileName);
    }

    onBtExport() {
        const params = {
            skipHeader: false,
            skipFooters: false,
            skipGroups: false,
            skipPinnedTop: false,
            skipPinnedBottom: false,
            allColumns: true,
            onlySelected: false,
            suppressQuotes: true,
            fileName: "CamAPS trainers",
            columnSeparator: ',',
            customHeader: "CamAPS trainers\n"
        };
        this.gridApi.exportDataAsCsv(params);
    }

    renderTrainersList() {
        return (
            <div style={{width: "100%", height: "100%"}}>
                <div className="pump-container">
                    <div className="page-container">
                        {this.state.isEditor ?
                            <Button className="button-add pull-left" bsStyle="primary" bsSize="small"
                                    onClick={this.onAddTrainer.bind(this)}>
                                Add trainer
                            </Button> : null
                        }
                        {this.state.isEditor ?
                            <Button className="button-duplicate pull-left" bsStyle="primary" bsSize="small"
                                    margin-left={20}
                                    disabled={this.state.selectedTrainers.length === 0}
                                    hidden={this.state.isEditor}
                                    onClick={this.onDuplicateTrainer.bind(this)}>
                                Duplicate trainer
                            </Button> : null
                        }
                        <Button className="button-duplicate pull-right" bsStyle="info" bsSize="small"
                                onClick={this.onBtExport.bind(this)}>
                            <Glyphicon glyph="file"/> Export to Excel
                        </Button>
                        <Button className="pull-right" bsStyle="info" bsSize="small"
                                onClick={this.onBtEmails.bind(this)}>
                            <Glyphicon glyph="file"/> Export emails
                        </Button>
                    </div>
                    <div className="page-container">
                        <div className="page-size-header pull-left">
                            Page size:&nbsp;
                            <select onChange={this.onPageSizeChanged.bind(this)} id="page-size" defaultValue={'100'}>
                                {/*<select id="page-size" defaultValue={'100'}>*/}
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                        <input className="pull-right" type="text" id="filter-text-box" placeholder="Search..."
                               onInput={this.onFilterTextBoxChanged.bind(this)}/>
                    </div>
                    <div style={{minHeight: "400px", height: "calc(100% - 30px)"}}>
                        <div
                            id="usersGrid"
                            style={this.state.style}
                            className="ag-theme-balham"
                        >
                            <AgGridReact
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowSelection={this.state.rowSelection}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                                pagination={true}
                                paginationPageSize={this.state.paginationPageSize}
                                paginationNumberFormatter={this.state.paginationNumberFormatter}
                                rowData={this.props.rowDataTrainers}
                                headerHeight={58}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>Access not allowed. Not authenticated</h1>
            </div>
        );
    }

    getHeader() {
        if (this.state.isLoading) {
            return ('Loading ...');
        } else if (!this.props.rowData || (this.props.rowData.length === 0)) {
            return ('CamAPS trainers');
        } else {
            return ('All CamAPS trainers');
        }
    }

    // getUser() {
    //     try {
    //         return ('User: ' + this.props.currentUser.attributes.name);
    //     } catch (e) {
    //         return ('');
    //     }
    // }

    renderTrainers() {
        return (
            <div className="pumps">

                {/*<div className="left-header pull-left">*/}
                <PageHeader>
                    <div className="header">
                        <div className="left-header pull-left">
                            {this.getHeader()}
                        </div>
                        {/*<div className="pull-right">*/}
                        {/*    <h4 className="text-right">*/}
                        {/*        {this.getUser()}*/}
                        {/*    </h4>*/}
                        {/*</div>*/}
                        <div className="clearfix"/>
                    </div>
                </PageHeader>
                {!this.state.isLoading && this.renderTrainersList()}
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderTrainers() : this.renderLander()}
            </div>
        );
    }
}
