import * as globalsUser from "./globalDefUsers";
import * as globals from "./globalDef";

/**
 * Shared ag-grid column definition for users
 * @type {*[]}
 */
export const columnDefsUsers = [{
    headerName: "User email", field: globalsUser.emailId,
    pinned: 'left',
    width: 200,
// }, {
//     headerName: "Email verified", field: globalsUser.email_verified,
//     width: 85,
}, {
    headerName: "Name", field: globalsUser.userName,
    width: 140
}, {
    headerName: "Distributor Id", field: globalsUser.distributorId,
    width: globals.standardColWidth
}, {
    headerName: "Allowed pumps", field: globalsUser.userTypeField,
    width: 98,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "CamAPS clinics ", field: globalsUser.clinicField,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "CamAPS trainers", field: globalsUser.trainerField,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "User training status", field: globalsUser.trainingStatusField,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "Customer emails", field: globalsUser.sendingEmailsField,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "CamAPS user access", field: globalsUser.usersField,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeViewer
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeEditor
        }
    }
}, {
    headerName: "Customer orders", field: globalsUser.orderField,
    width: globals.narrowColWidth + 6,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "VAT exemptions", field: globalsUser.exemptionField,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "Discount codes", field: globalsUser.discountField,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "Version messages", field: globalsUser.versionField,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "Received notices", field: globalsUser.noticesField,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "Customers info", field: globalsUser.customersField,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-amber': function (params) {
            return params.value === globalsUser.userTypeEditor
        },
        'rag-red': function (params) {
            return params.value === globalsUser.userTypeAdminEditor
        }
    }
}, {
    headerName: "User enabled", field: globalsUser.enabledUser,
    width: globals.narrowColWidth,
    cellClassRules: {
        'rag-red': function (params) {
            return (!params.value) || params.value === "false"
        }
    }
}, {
    headerName: "User status", field: globalsUser.userStatus,
    width: 120
}, {
    headerName: "Created", field: globalsUser.dateCreatedUser,
}, {
    headerName: "Last modified", field: globalsUser.lastModifiedDateUser,
},
];

/**
 * Update column definition for data entry
 */
export function setColDefUserForDataEntry(editting, self) {
// remove items which not entered
    let field;
    let i;
    if (editting) {
        self.state.columnDefs.push({
            headerName: "Password", field: globalsUser.resetPasswordHeading,
            width: globals.narrowColWidth
        });
    }

    for (i = self.state.columnDefs.length - 1; i >= 0; i--) {
        field = self.state.columnDefs[i];
        if ((field.field === globalsUser.dateCreatedUser) ||
            (field.field === globalsUser.lastModifiedDateUser) ||
            (field.field === globalsUser.email_verified) ||
            (field.field === globalsUser.userStatus)) {
            self.state.columnDefs.splice(i, 1);
        } else if (field.field === globalsUser.userTypeField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeEditor,
                    globalsUser.userTypeAdminViewer,
                    globalsUser.userTypeAdminEditor]
            };
            field.cellClassRules = {
                'rag-amber': function (params) {
                    return params.value === globalsUser.userTypeEditor
                },
                'rag-red': function (params) {
                    return params.value === globalsUser.userTypeAdminEditor
                }
            };
        } else if (field.field === globalsUser.resetPasswordHeading) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.resetPassword]
            };
        } else if (field.field === globalsUser.clinicField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    globalsUser.userTypeEditor]
            };
        } else if (field.field === globalsUser.trainerField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    globalsUser.userTypeEditor]
            };
        } else if (field.field === globalsUser.trainingStatusField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    globalsUser.userTypeEditor]
            };
        } else if (field.field === globalsUser.usersField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    globalsUser.userTypeEditor]
            };
        } else if (field.field === globalsUser.sendingEmailsField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    globalsUser.userTypeEditor]
            };
        } else if (field.field === globalsUser.orderField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    // globalsUser.userTypeEditor
                ]
            };
        } else if (field.field === globalsUser.exemptionField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    // globalsUser.userTypeEditor
                ]
            };
        } else if (field.field === globalsUser.customersField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    globalsUser.userTypeEditor]
            };
        } else if (field.field === globalsUser.discountField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    globalsUser.userTypeEditor]
            };
        } else if (field.field === globalsUser.versionField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    globalsUser.userTypeEditor]
            };
        } else if (field.field === globalsUser.noticesField) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: [
                    '',
                    globalsUser.userTypeViewer,
                    // globalsUser.userTypeEditor
                ]
            };
        } else if (field.field === globalsUser.enabledUser) {
            field.cellEditor = "agSelectCellEditor";
            field.cellEditorParams = {
                values: ['true', 'false']
            };
        }
        if (editting) {
            if (field.field !== globalsUser.emailId) {
                field.editable = true;
            }
        } else {
            field.editable = true;
        }
    }
}
