/**
 * Attributed suffix
 * @type {string}
 */
export const attrib_suffix = "";

/**
 * Trainer ID
 * @type {string}
 */
export const trainerID = "trainerID" + attrib_suffix;

/**
 * Trainer name
 * @type {string}
 */
export const trainerName = "trainerName" + attrib_suffix;

/**
 * Trainer  email
 * @type {string}
 */
export const trainerEmail = "trainerEmail" + attrib_suffix;

/**
 * Trainer clinic
 * @type {string}
 */
export const trainerClinic = "trainerClinic" + attrib_suffix;

/**
 * Trainer certification date
 * @type {string}
 */
export const trainerDateCertified = "trainerDateCertified" + attrib_suffix;

/**
 * Certification method
 * @type {string}
 */
export const certificationMethod = "certificationMethod" + attrib_suffix;

/**
 * Closed loop system: FX or HX
 * @type {string}
 */
export const closedLoopSystem = "closedLoopSystem" + attrib_suffix;

/**
 * Training type: professional or user
 * @type {string}
 */
export const trainingType = "trainingType" + attrib_suffix;

/**
 * Language (locale)
 * @type {string}
 */
export const locale = "locale" + attrib_suffix;

/**
 * Whether trainer is suspended
 * @type {string}
 */
export const suspended = "suspended" + attrib_suffix;

/**
 * When record created
 * @type {string}
 */
export const createdAt = "createdAt" + attrib_suffix;

/**
 * When record created
 * @type {string}
 */
export const lastModifiedAt = "lastModifiedAt" + attrib_suffix;