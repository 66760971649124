import * as globalsOrder from "./globalDefOrders";
import * as globals from "./globalDef";

/**
 * Shared ag-grid column definition for trainers
 * @type {*[]}
 */
export const columnDefsOrders = [{
    headerName: "Customer email", field: globalsOrder.orderID,
    pinned: 'left',
    width: 150,
}, {
    headerName: "Order date/time", field: globalsOrder.orderTime,
    width: globals.standardColWidth
}, {
    headerName: "First name", field: globalsOrder.firstName,
    width: 100
}, {
    headerName: "Last name", field: globalsOrder.lastName,
    width: 130
}, {
    headerName: "Pump SN", field: globalsOrder.pumpSN,
    width: 120,
}, {
    headerName: "Training clinic", field: globalsOrder.trainingClinic,
    width: 150
}, {
    headerName: "Subscription (months)", field: globalsOrder.subscription,
    width: globals.standardColWidth
}, {
    headerName: "Cost summary", field: globalsOrder.comment,
    width: 160
}, {
    headerName: "Cost", field: globalsOrder.cost,
    width: globals.standardColWidth
}, {
    headerName: "Currency", field: globalsOrder.currency,
    width: globals.standardColWidth
}, {
    headerName: "VAT exempt", field: globalsOrder.exemptFromVat,
    width: globals.standardColWidth
}, {
    headerName: "Discount code ", field: globalsOrder.discountCode,
    width: globals.standardColWidth
}, {
    headerName: "Discount duration (months)", field: globalsOrder.discountDurationMonths,
    width: globals.standardColWidth
}, {
    headerName: "Discount (%)", field: globalsOrder.discountPercentage,
    width: globals.standardColWidth
}, {
    headerName: "Heard from", field: globalsOrder.heardFrom,
    width: globals.standardColWidth
}, {
    headerName: "Locale", field: globalsOrder.locale,
    width: globals.standardColWidth
}, {
    headerName: "Created", field: globalsOrder.createdAt,
    width: globals.standardColWidth
}, {
    headerName: "Last modified", field: globalsOrder.lastModifiedAt,
    width: globals.standardColWidth
},
];

