import React, {Component} from "react";
import {API} from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./Pumps.css";
import * as columnDefinition from "../libs/columnDef";
import * as globals from "../libs/globalDef";
import {AgGridReact} from "ag-grid-react";
import * as userLib from "../libs/user-lib";
import {addDistributor, validateForm} from "../libs/user-lib";

export default class Pumps extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            isDeleting: null,
            pump: null,

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefinition.columnDefs)),

            defaultColDef: {
                resizable: true
            },

            style: {
                width: '100%',
                // height: '364px'
                height: '84px'
            },

            isAdminEditor: userLib.isAdminEditor(this.props.groups),
            isEditor: userLib.isEditor(this.props.groups),

            // components: {datePicker: this.getDatePicker()},
            rowData: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // add distributor fields
        addDistributor(this);
        // update column definition
        columnDefinition.setColDefForDataEntry(true, this);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowData: data});
        };

        updateData(this.state.rowData);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.doLayout();
        }
    }

    recalculateRemainingDays = (params) => {
        const element = params.api.getDisplayedRowAtIndex(0).data;
        element[globals.remainingDays] = userLib.calculateDaysLeft(element[globals.startDate],
            element[globals.expiryDate]);
        params.api.refreshCells();
    };

    async componentDidMount() {
        try {
            const pump = await this.getPump();
            pump[globals.remainingDays] = userLib.calculateDaysLeft(pump[globals.startDate],
                pump[globals.expiryDate]);
            if (pump[globals.pumpMake]
                && pump[globals.pumpMake].includes('Ypso')
                && (!pump[globals.paymentModel])) {
                pump[globals.paymentModel] = 'Access fee'
            }
            this.setState({rowData: [pump]});

        } catch (e) {
            if (e.response && e.response.data && e.response.data.messageUser) {
                this.props.processAlert(e.response.data.messageUser)
            } else {
                alert(e);
            }
        }
        this.props.setUpdateTimeOutListener();
    }

    getPump() {
        return API.get("pumps", `/pumps/${this.props.match.params.id}`, {});
    }

    savePump(pump) {
        let attrib;
        for (attrib in pump) {
            if (Object.prototype.hasOwnProperty.call(pump, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }
        return API.put("pumps", `/pumps/${this.props.match.params.id}`, {
            body: pump
        });
    }

    deletePump() {
        // return API.del("pumps", `/pumps/${this.props.match.params.id}`, {});
        const options = {deleteItem: true};
        return API.put("pumps", `/pumps/${this.props.match.params.id}`, {
            body: options
        });
    }

    handleSubmit = async event => {

        event.preventDefault();
        this.gridApi.stopEditing();
        if (validateForm(this)) {
            this.setState({isLoading: true});

            try {
                const element = this.gridApi.getDisplayedRowAtIndex(0).data;
                if (element[globals.commercial] && (typeof element[globals.commercial] === 'string')) {
                    element[globals.commercial] = element[globals.commercial].toLowerCase() === "true";
                }
                await this.savePump(element);
                // reload data
                this.props.setRowData([]);
                this.props.history.push("/pumps");
            } catch (e) {
                if (e.response && e.response.data && e.response.data.messageUser) {
                    this.props.processAlert(e.response.data.messageUser)
                } else {
                    alert(e);
                }
                this.setState({isLoading: false});
            }
        }
    };

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this pump?"
        );

        if (!confirmed) {
            return;
        }

        this.setState({isDeleting: true});

        try {
            await this.deletePump();
            // reload data
            this.props.setRowData([]);
            this.props.history.push("/pumps");
        } catch (e) {
            if (e.response && e.response.data && e.response.data.messageUser) {
                this.props.processAlert(e.response.data.messageUser)
            } else {
                alert(e);
            }
            this.setState({isDeleting: false});
        }
    };

    render() {
        return (
            <div className="Pumps">
                {this.state.rowData &&
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            // components={this.state.components}
                            rowData={this.state.rowData}
                        />
                    </div>
                    {(this.state.isAdminEditor || this.state.isEditor) &&
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving…"
                    />
                    }
                    {this.state.isAdminEditor &&
                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"
                    />
                    }
                </form>}
            </div>
        );
    }
}
