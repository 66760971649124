import * as globalsExemption from "./globalDefExemptions";
import * as globals from "./globalDef";

/**
 * Shared ag-grid column definition for trainers
 * @type {*[]}
 */
export const columnDefsExemptions = [{
    headerName: "Customer email", field: globalsExemption.exemptionID,
    pinned: 'left',
    width: 150,
}, {
    headerName: "Order date/time", field: globalsExemption.orderTime,
    width: globals.standardColWidth
}, {
    headerName: "Supplied product", field: globalsExemption.suppliedProduct,
    width: 110
}, {
    headerName: "First name", field: globalsExemption.firstNameVatExempt,
    width: 130
}, {
    headerName: "Last name", field: globalsExemption.lastNameVatExempt,
    width: 130
}, {
    headerName: "Address 1", field: globalsExemption.addressLine1VatExempt,
    width: 120,
}, {
    headerName: "Address 2", field: globalsExemption.addressLine2VatExempt,
    width: 150
}, {
    headerName: "Address 3", field: globalsExemption.addressLine3VatExempt,
    width: globals.standardColWidth
}, {
    headerName: "Postcode", field: globalsExemption.postcodeVatExempt,
    width: globals.standardColWidth
}, {
    headerName: "Health condition", field: globalsExemption.conditionVatExempt,
    width: 130
}, {
    headerName: "Signed by ", field: globalsExemption.signatureVatExempt,
    width: 140
}, {
    headerName: "Date signed", field: globalsExemption.dateSignedVatExempt,
    width: globals.standardColWidth
}, {
    headerName: "Created", field: globalsExemption.createdAt,
    width: globals.standardColWidth
}, {
    headerName: "Last modified", field: globalsExemption.lastModifiedAt,
    width: globals.standardColWidth
},
];
