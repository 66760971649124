import React, {Component} from "react";
import {API} from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./User.css";
import {AgGridReact} from "ag-grid-react";
import * as userLib from "../libs/user-lib";
import {columnDefsUsers, setColDefUserForDataEntry} from "../libs/columnDefUsers";
import {validateFormUser} from "../libs/user-lib";
import {extractUserInfo} from "../libs/user-lib";
import * as globalDefUsers from "../libs/globalDefUsers";

export default class User extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            isDeleting: null,
            user: null,

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsUsers)),

            defaultColDef: {
                resizable: true
            },

            style: {
                width: '100%',
                // height: '364px'
                height: '105px'
            },

            isAdminEditor: userLib.isAdminEditor(this.props.groups),

            // components: {datePicker: this.getDatePicker()},
            rowDataUsers: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // update column definition
        setColDefUserForDataEntry(true, this);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowDataUsers: data});
        };

        updateData(this.state.rowDataUsers);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.doLayout();
        }
    }

    async componentDidMount() {
        try {
            const user = await this.getUser();
            const object = extractUserInfo(user.Items);
            object[globalDefUsers.resetPasswordHeading] = " ";
            this.setState({rowDataUsers: [object]});
        } catch (e) {
            if (e.response && e.response.data && e.response.data.messageUser) {
                this.props.processAlert(e.response.data.messageUser)
            } else {
                alert(e);
            }
        }
        this.props.setUpdateTimeOutListener();
    }

    getUser() {
        return API.get("pumps", `/users/${this.props.match.params.id}`, {});
    }

    saveUser(user, reset) {
        const object = {
            Username: user[globalDefUsers.subId],
            UserType: user[globalDefUsers.userTypeField],
            UserAttributes: [
                {"Name": globalDefUsers.userName, "Value": user[globalDefUsers.userName]},
                {"Name": globalDefUsers.distributorId, "Value": user[globalDefUsers.distributorId] || " "},
                {"Name": globalDefUsers.userTypeField, "Value": user[globalDefUsers.userTypeField] || " "},
                {"Name": globalDefUsers.clinicField, "Value": user[globalDefUsers.clinicField] || " "},
                {"Name": globalDefUsers.trainerField, "Value": user[globalDefUsers.trainerField] || " "},
                {"Name": globalDefUsers.trainingStatusField, "Value": user[globalDefUsers.trainingStatusField] || " "},
                {"Name": globalDefUsers.sendingEmailsField, "Value": user[globalDefUsers.sendingEmailsField] || " "},
                {"Name": globalDefUsers.usersField, "Value": user[globalDefUsers.usersField] || " "},

                {"Name": globalDefUsers.orderField, "Value": user[globalDefUsers.orderField] || " "},
                {"Name": globalDefUsers.exemptionField, "Value": user[globalDefUsers.exemptionField] || " "},
                {"Name": globalDefUsers.discountField, "Value": user[globalDefUsers.discountField] || " "},
                {"Name": globalDefUsers.versionField, "Value": user[globalDefUsers.versionField] || " "},
                {"Name": globalDefUsers.noticesField, "Value": user[globalDefUsers.noticesField] || " "},
                {"Name": globalDefUsers.customersField, "Value": user[globalDefUsers.customersField] || " "},
            ],
            UserEnabled: (user[globalDefUsers.enabledUser] === "true") || (user[globalDefUsers.enabledUser] === true)
                ? "true" : null,
            ResetPassword: reset || user[globalDefUsers.resetPasswordHeading]
        };
        return API.put("pumps", `/users/${this.props.match.params.id}`, {
            body: object
        });
    }

    deleteUser() {
        return API.del("pumps", `/users/${this.props.match.params.id}`, {});
    }

    handleSubmit = async event => {

        event.preventDefault();
        this.gridApi.stopEditing();
        if (validateFormUser(this)) {
            this.setState({isLoading: true});

            try {
                const element = this.gridApi.getDisplayedRowAtIndex(0).data;
                await this.saveUser(element, false);
                // reload data
                this.props.setRowDataUsers([]);
                this.props.history.push("/users");
            } catch (e) {
                if (e.response && e.response.data && e.response.data.messageUser) {
                    this.props.processAlert(e.response.data.messageUser)
                } else {
                    alert(e);
                }
                this.setState({isLoading: false});
            }
        }
    };

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this user? This cannot be undone."
        );

        if (!confirmed) {
            return;
        }

        this.setState({isDeleting: true});

        try {
            await this.deleteUser();
            // reload data
            this.props.setRowDataUsers([]);
            this.props.history.push("/users");
        } catch (e) {
            if (e.response && e.response.data && e.response.data.messageUser) {
                this.props.processAlert(e.response.data.messageUser)
            } else {
                alert(e);
            }
            this.setState({isDeleting: false});
        }
    };

    render() {
        return (
            <div className="User">
                {this.state.rowDataUsers &&
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            // components={this.state.components}
                            rowData={this.state.rowDataUsers}
                            headerHeight={58}
                        />
                    </div>
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving…"
                    />
                    {this.state.isAdminEditor &&
                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"
                    />
                    }
                </form>}
            </div>
        );
    }
}
