import React, {Component} from "react";
import {API} from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./Clinic.css";
import * as columnDefsClinics from "../libs/columnDefClinics";
import {AgGridReact} from "ag-grid-react";
import * as userLib from "../libs/user-lib";
import {validateFormClinic} from "../libs/user-lib";

export default class Clinic extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            isDeleting: null,
            pump: null,

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsClinics.columnDefsClinics)),

            defaultColDef: {
                resizable: true
            },

            getRowHeight: function (params) {
                const pumpsLen = params.data.pumps ? params.data.pumps.length : 0;
                const dayLen = params.data.reportDay ? params.data.reportDay.length : 0;
                const weekLen = params.data.reportWeek ? params.data.reportWeek.length : 0;
                const monthLen = params.data.reportMonth ? params.data.reportMonth.length : 0;
                const maxLen = Math.max(pumpsLen, dayLen, weekLen, monthLen);
                return 28 * (Math.floor(maxLen / 25.5) + 1);
            },

            style: {
                width: '100%',
                // height: '364px'
                height: '284px'
            },

            isEditor: userLib.isCustomEditor(this.props.currentUser, 'clinic'),

            rowData: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // update column definition
        columnDefsClinics.setColDefClinicForDataEntry(true, this);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowData: data});
        };

        updateData(this.state.rowData);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.doLayout();
        }
    }

    async componentDidMount() {
        try {
            const clinic = await this.getClinic();
            this.reformatStringArrays(clinic);
            this.setState({rowData: [clinic]});
        } catch (e) {
            if (e.response && e.response.data && e.response.data.messageUser) {
                this.props.processAlert(e.response.data.messageUser)
            } else {
                alert(e);
            }
        }
        this.props.setUpdateTimeOutListener();
    }

    reformatStringArrays(clinic) {
        clinic.pumps = clinic.pumps.join(', ');
        clinic.reportDay = clinic.reportDay.join(', ');
        clinic.reportWeek = clinic.reportWeek.join(', ');
        clinic.reportMonth = clinic.reportMonth.join(', ');
    }

    getClinic() {
        return API.get("pumps", `/clinics/${this.props.match.params.id}`, {});
    }

    saveClinic(clinic) {
        let attrib;
        for (attrib in clinic) {
            if (Object.prototype.hasOwnProperty.call(clinic, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }
        if (clinic.pumps) {
            clinic.pumps = clinic.pumps.replace(/\s/g, '').split(',');
        } else {
            clinic.pumps = [];
        }
        if (clinic.reportDay) {
            clinic.reportDay = clinic.reportDay.replace(/\s/g, '').split(',');
        } else {
            clinic.reportDay = [];
        }
        if (clinic.reportWeek) {
            clinic.reportWeek = clinic.reportWeek.replace(/\s/g, '').split(',');
        } else {
            clinic.reportWeek = [];
        }
        if (clinic.reportMonth) {
            clinic.reportMonth = clinic.reportMonth.replace(/\s/g, '').split(',');
        } else {
            clinic.reportMonth = [];
        }
        clinic.adminID = clinic.adminID.toLowerCase();
        clinic.financeID = clinic.financeID ? clinic.financeID.toLowerCase() : null;
        return API.put("pumps", `/clinics/${this.props.match.params.id.toLowerCase()}`, {
            body: clinic
        });
    }

    deleteClinic() {
        // return API.del("pumps", `/clinics/${this.props.match.params.id}`, {});
        const options = {deleteItem: true};
        return API.put("pumps", `/clinics/${this.props.match.params.id}`, {
            body: options
        });
    }

    handleSubmit = async event => {

        event.preventDefault();
        this.gridApi.stopEditing();
        if (validateFormClinic(this)) {
            this.setState({isLoading: true});
            let element;
            try {
                element = this.gridApi.getDisplayedRowAtIndex(0).data;
                await this.saveClinic(element);
                this.reformatStringArrays(element);
                // reload data
                this.props.setRowDataClinics([]);
                this.props.history.push("/clinics");
            } catch (e) {
                this.reformatStringArrays(element);
                if (e.response && e.response.data && e.response.data.messageUser) {
                    this.props.processAlert(e.response.data.messageUser)
                } else {
                    alert(e);
                }
                this.setState({isLoading: false});
            }
        }
    };

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this clinic? This cannot be undone."
        );

        if (!confirmed) {
            return;
        }

        this.setState({isDeleting: true});

        try {
            await this.deleteClinic();
            // reload data
            this.props.setRowDataClinics([]);
            this.props.history.push("/clinics");
        } catch (e) {
            if (e.response && e.response.data && e.response.data.messageUser) {
                this.props.processAlert(e.response.data.messageUser)
            } else {
                alert(e);
            }
            this.setState({isDeleting: false});
        }
    };

    render() {
        return (
            <div className="Pumps">
                {this.state.rowData &&
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            getRowHeight={this.state.getRowHeight}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            // components={this.state.components}
                            rowData={this.state.rowData}
                            headerHeight={58}
                        />
                    </div>
                    {this.state.isEditor &&
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving…"
                    />
                    }
                    {this.state.isEditor &&
                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"
                    />
                    }
                </form>}
            </div>
        );
    }
}
