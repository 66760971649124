import React, {Component} from "react";
import {API} from "aws-amplify";
import {Button, Glyphicon, PageHeader} from "react-bootstrap";
import "./Customers.css";
import {AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import * as userLib from "../libs/user-lib";
import {columnDefsCustomers} from "../libs/columnDefCustomers";
import {extractUserInfoString} from "../libs/user-lib";
import LoaderButton from "../components/LoaderButton";
import * as globals from "../libs/globalDef";

export default class Customers extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            isDeleting: null,
            customers: [],
            pumps: this.props.rowData,
            selectedCustomers: [],
            isEditor: userLib.isCustomEditor(this.props.currentUser, 'customer'),
            isViewer: userLib.isCustomViewer(this.props.currentUser, 'customer'),

            columnDefs: columnDefsCustomers,

            defaultColDef: {
                sortable: true,
                filter: true,
                lockPinned: true,
                resizable: true
            },
            style: {
                width: '100%',
                height: '644px'
                // height: '364px'
                // height: '2884px'
            },
            rowSelection: "single",
            paginationPageSize: 100,
            paginationNumberFormatter: function (params) {
                return params.value.toLocaleString();
            }
        };

        this.state.columnDefs[0].checkboxSelection = true;
        // const self = this;
        this.state.columnDefs[0].cellRenderer = function (params) {
            let link = document.createElement('a');
            link.href = '#';
            link.innerText = params.value;
            link.addEventListener('click', (e) => {
                e.preventDefault();
                // if (self.state.isEditor) {
                //     self.props.setCustomerToDuplicate(null);
                //     self.props.history.push('/customers/' + params.data[subId]);
                // }
            });
            return link;
        };
    }

    onGridReady = params => {
        this.gridApi = params.api;
        // this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.props.setRowDataCustomers(data);
            params.api.paginationGoToPage(0);
        };

        updateData(this.props.rowDataCustomers);
        const value = this.props.searchBoxValue;
        document.getElementById('filter-text-box').value = value;
        params.api.setQuickFilter(value);

        const valuePageSize = this.props.pageSize;
        document.getElementById("page-size").value = valuePageSize;
        this.gridApi.paginationSetPageSize(Number(valuePageSize));
    };


    onPageSizeChanged() {
        // const pxPerRow = 28;
        const value = document.getElementById("page-size").value;
        this.props.setPageSize(value);
        this.gridApi.paginationSetPageSize(Number(value));

        // const newHeight = ((Number(value) + 3) * pxPerRow).toString() + "px";
        // this.setHeight(newHeight);
    }

    onSelectionChanged() {
        this.setState({selectedCustomers: this.gridApi.getSelectedRows()});
    }

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.doLayout();
        }
    }

    async componentDidMount() {
        if (!this.props.isAuthenticated) {
            return;
        }

        this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'customer')});
        this.setState({isViewer: userLib.isCustomViewer(this.props.currentUser, 'customer')});

        if (this.props.rowDataCustomers.length === 0) {
            try {
                let objectPumps = {};
                let i;
                for (i = 0; i < this.state.pumps.length; i++) {
                    objectPumps[this.state.pumps[i][globals.pumpSN]] = i;
                }

                const customers = await this.customers("");
                let tempResult = customers;
                while (tempResult.LastEvaluatedKey) {
                    tempResult = await this.customers(tempResult.LastEvaluatedKey);
                    customers.Items = customers.Items.concat(tempResult.Items);
                }
                const extractedCustomers = [];
                let customer;
                for (customer of customers.Items) {
                    const object = extractUserInfoString(customer);
                    extractedCustomers.push(object);

                    // add pump attributes
                    let objectPumpSN = object[globals.pumpSN];
                    if (objectPumpSN && (objectPumpSN.length > 3)) {
                        objectPumpSN = objectPumpSN.substring(0, objectPumpSN.length - 3);

                        let idx = objectPumps[objectPumpSN];
                        if (idx) {
                            object[globals.distributorName] = this.state.pumps[idx][globals.distributorName];
                            object[globals.clinicID] = this.state.pumps[idx][globals.clinicID];
                            object[globals.codePO] = this.state.pumps[idx][globals.codePO];
                            object[globals.expiryDatePO] = this.state.pumps[idx][globals.expiryDatePO];
                            object[globals.inHclPilot] = this.state.pumps[idx][globals.inHclPilot];
                            object[globals.owner] = this.state.pumps[idx][globals.owner];
                        }
                    }
                }

                this.props.setRowDataCustomers(extractedCustomers);
                this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'customer')});
                this.setState({isViewer: userLib.isCustomViewer(this.props.currentUser, 'customer')});
            } catch (e) {
                if (e.response && e.response.data && e.response.data.messageUser) {
                    this.props.processAlert(e.response.data.messageUser)
                } else {
                    alert(e);
                }
            }
        } else {
            this.setState({isEditor: userLib.isCustomEditor(this.props.currentUser, 'customer')});
            this.setState({isViewer: userLib.isCustomViewer(this.props.currentUser, 'customer')});
        }

        this.props.setUpdateTimeOutListener();
        this.setState({isLoading: false});
    }

    customers(lastEvaluatedKey) {
        let myInit = {
            queryStringParameters: {}
        };
        if (lastEvaluatedKey && ("" !== lastEvaluatedKey.userId)) {
            myInit.queryStringParameters.ExclusiveStartKey = lastEvaluatedKey.userId;
        }
        return API.get("pumps", "/customers", myInit);
    }

    onFilterTextBoxChanged() {
        if (this.gridApi) {
            const value = document.getElementById('filter-text-box').value;
            this.props.setSearchBox(value);
            this.gridApi.setQuickFilter(value);
        }
    }

    onDeleteCustomer = async event => {
        alert("For Germany users using CamAPS under insurance\n\n" +
            "Before deleting the user account, please inform the affected person " +
            "by email of data that are going to be lost.");

        event.preventDefault();

        const customerToDelete = this.state.selectedCustomers[0];
        const password = prompt("Are you sure you want to delete all data associated  with \"" +
            customerToDelete.email + "\"? All data will be erased apart from VAT exception record. \n\nTo proceed, " +
            "please enter password", "");

        if (!password || (password === "")) {
            return;
        }
        this.setState({isDeleting: true});
        let cont = true;
        let tries = 0;
        let maxTries = 60;
        do {

            try {
                const response = await this.deleteCustomer(customerToDelete, password);
                // reload data
                this.props.setRowDataCustomers([]);
                this.setState({customers: []});
                this.setState({selectedCustomers: []});
                this.setState({isLoading: true});
                alert("Customer deleted. \n\nNOTE: You need to delete manually the following pump(s) from " +
                    "\"Allowed pumps\": \"" + response.foundPumps.join(', ') + "\"");
                this.componentDidMount();
                cont = false;
            } catch (e) {
                if (e.response && e.response.data && e.response.data.messageUser) {
                    this.props.processAlert(e.response.data.messageUser)
                    cont = false;
                } else {
                    tries++;
                    if ((tries >= maxTries) ||
                        (!(e.response && e.response.data && (e.response.data.message === "Endpoint request timed out")))) {
                        alert(e);
                        cont = false;
                    }
                }
            }
        } while (cont);
        this.setState({isDeleting: false});
    };

    deleteCustomer(customerToDelete, password) {
        // return API.del("pumps", `/pumps/${this.props.match.params.id}`, {});
        const options = {
            queryStringParameters: {
                deleteItem: true,
                password: password,
                customerToDelete: customerToDelete.email,
            }
        };
        return API.get("pumps", "/customers", options);
    }

    onBtExport() {
        const params = {
            skipHeader: false,
            skipFooters: false,
            skipGroups: false,
            skipPinnedTop: false,
            skipPinnedBottom: false,
            allColumns: true,
            onlySelected: false,
            suppressQuotes: true,
            fileName: "CamAPS customers",
            columnSeparator: ',',
            customHeader: "CamAPS customers\n"
        };
        this.gridApi.exportDataAsCsv(params);
    }

    renderCustomersList() {
        return (
            <div style={{width: "100%", height: "100%"}}>
                <div className="pump-container">
                    <div className="page-container">
                        <div className="btn-del-customer">
                            {this.state.isEditor ?
                                <LoaderButton
                                    className="button-add pull-left"
                                    block
                                    bsStyle="danger"
                                    bsSize="small"
                                    disabled={this.state.selectedCustomers.length === 0}
                                    isLoading={this.state.isDeleting}
                                    hidden={this.state.isEditor}
                                    text="Delete customer"
                                    loadingText="Deleting…"
                                    onClick={this.onDeleteCustomer.bind(this)}>
                                </LoaderButton> : null
                            }
                        </div>
                        <Button className="pull-right" bsStyle="info" bsSize="small"
                                onClick={this.onBtExport.bind(this)}>
                            <Glyphicon glyph="file"/> Export to Excel
                        </Button>
                    </div>
                    <div className="page-container">
                        <div className="page-size-header pull-left">
                            Page size:&nbsp;
                            <select onChange={this.onPageSizeChanged.bind(this)} id="page-size" defaultValue={'100'}>
                                {/*<select id="page-size" defaultValue={'100'}>*/}
                                <option value="10">10</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                                <option value="1000">1000</option>
                            </select>
                        </div>
                        <input className="pull-right" type="text" id="filter-text-box" placeholder="Search..."
                               onInput={this.onFilterTextBoxChanged.bind(this)}/>
                    </div>
                    <div style={{minHeight: "400px", height: "calc(100% - 30px)"}}>
                        <div
                            id="customersGrid"
                            style={this.state.style}
                            className="ag-theme-balham"
                        >
                            <AgGridReact
                                columnDefs={this.state.columnDefs}
                                defaultColDef={this.state.defaultColDef}
                                rowSelection={this.state.rowSelection}
                                onGridReady={this.onGridReady}
                                enableCellTextSelection={true}
                                onSelectionChanged={this.onSelectionChanged.bind(this)}
                                pagination={true}
                                paginationPageSize={this.state.paginationPageSize}
                                paginationNumberFormatter={this.state.paginationNumberFormatter}
                                rowData={this.props.rowDataCustomers}
                                headerHeight={58}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderLander() {
        return (
            <div className="lander">
                <h1>Access not allowed. Not authenticated</h1>
            </div>
        );
    }

    getHeader() {
        if (this.state.isLoading) {
            return ('Loading ...');
        } else if (!this.props.rowData || (this.props.rowData.length === 0)) {
            return ('CamAPS customers');
        } else {
            return ('All CamAPS customers');
        }
    }

    renderCustomers() {
        return (
            <div className="pumps">
                {/*<div className="left-header pull-left">*/}
                <PageHeader>
                    <div className="header">
                        <div className="left-header pull-left">
                            {this.getHeader()}
                        </div>
                        <div className="clearfix"/>
                    </div>
                </PageHeader>
                {!this.state.isLoading && this.renderCustomersList()}
            </div>
        );
    }

    render() {
        return (
            <div className="Home">
                {this.props.isAuthenticated ? this.renderCustomers() : this.renderLander()}
            </div>
        );
    }
}
