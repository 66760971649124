/**
 * Pump serial number
 * @type {string}
 */
export const pumpSN = "pumpSN";

/**
 * Distributor name attribute
 * @type {string}
 */
export const distributorName = "distributorName";

/**
 * Distributor ID
 * @type {string}
 */
export const distributorId = "distributorId";

/**
 * Commercial attribute
 * @type {string}
 */
export const commercial = "commercial";

/**
 * Clinic ID for PO data
 * @type {string}
 */
export const clinicID = "clinicID";

/**
 * PO attribute
 * @type {string}
 */
export const codePO = "codePO";

/**
 * PO expiry date
 * @type {string}
 */
export const expiryDatePO = "expiryDatePO";

/**
 * Currency attribute
 * @type {string}
 */
export const currency = "currency";

/**
 * Annual cost attribute
 * @type {string}
 */
export const annualCost = "annualCost";

/**
 * Monthly cost attribute
 * @type {string}
 */
export const monthlyCost = "monthlyCost";

/**
 * Cost model attribute
 * @type {string}
 */
export const costModel = "costModel";

/**
 * Start date attribute
 * @type {string}
 */
export const startDate = "startDate";

/**
 * Expiry date attribute
 * @type {string}
 */
export const expiryDate = "expiryDate";


/**
 * Whether pump expiry date is automatically extendable.
 *
 * If not, end date is fixed
 * @type {string}
 */
export const extendable = "extendable";

/**
 * Whether pump is in HCL pilot
 *
 * @type {string}
 */
export const inHclPilot = "inHclPilot";

/**
 * Days left
 * @type {string}
 */
export const remainingDays = "daysLeft";

/**
 * Pump make attribute
 * @type {string}
 */
export const pumpMake = "pumpMake";

/**
 * Payment model for Ypso pump
 * @type {string}
 */
export const paymentModel = "paymentModel";

/**
 * Omit tamper check
 * @type {string}
 */
export const omitTamper = "omitTamper";

/**
 * Study attribute
 * @type {string}
 */
export const study = "study";

/**
 * Site attribute
 * @type {string}
 */
export const site = "site";

/**
 * Owner of the pup (this would be rep who originated the sale)
 * @type {string}
 */
export const owner = "pumpOwner";

/**
 * Upload attribute
 * @type {string}
 */
export const upload = "upload";

/**
 * Variant attribute
 * @type {string}
 */
export const variant = "variant";

/**
 * Whether the pump has "already trained" status
 *
 * This is used for replacement pumps and demo pumps
 * @type {string}
 */
export const isTrained = "isTrainedStatus";

/**
 * Comment attribute
 * @type {string}
 */
export const comment = "comment";

/**
 * User comment attribute
 * @type {string}
 */
export const userComment = "userComment";

/**
 * Created at attribute
 * @type {string}
 */
export const createdAt = "createdAt";


/**
 * Modified at attribute
 * @type {string}
 */
export const modifiedAt = "modifiedAt";

/**
 * User initials attribute
 * @type {string}
 */
export const userInitials = "userInitials";

/**
 * Whether free trial attribute
 * @type {string}
 */
export const freeTrial = "freeTrial";

/**
 * Order cancelled attribute
 * @type {string}
 */
export const cancelled = "cancelled";

/**
 * Supplier attribute
 * @type {string}
 */
export const supplier = "supplier";

/**
 * User email
 * @type {string}
 */
export const userEmail = "userEmail";

/**
 * User email
 * @type {string}
 */
export const userId = "userId";

/**
 * Nickname
 * @type {string}
 */
export const nickname = "nickname";

/**
 * Date when app used (GMT)
 * @type {string}
 */
export const dateUsed = "dateUsed";

/**
 * Date when closed loop started (phone time)
 * @type {string}
 */
export const dateClStarted = "dateClcStartedPhoneTime";

/**
 * App version
 * @type {string}
 */
export const appVersion = "appVersion";

/**
 * App variant
 * @type {string}
 */
export const appVariant = "appVariant";

/**
 * Phone model
 * @type {string}
 */
export const phoneModel = "phoneModel";

/**
 * CGM transmitter info
 * @type {string}
 */
export const transmitter = "CGM";

/**
 * Hash of user name
 * @type {string}
 */
export const userIdHash = "userNameHash";

/**
 * Country from locale
 * @type {string}
 */
export const country = "country";

/**
 * Locale set on the phone
 * @type {string}
 */
export const locale = "locale";

/**
 * Age category (adult of paeds)
 * @type {string}
 */
export const ageCategory = "ageCategory";

/**
 * Date (year) of birth
 * @type {string}
 */
export const dateOfBirth = "dateOfBirth";

/**
 * Date/time
 * @type {string}
 */
export const dateTime = "date-time";

/**
 * The number of invited pump followers
 * @type {string}
 */
export const invitedCloudFollowers = "activeCloudFollowers";

/**
 * CAD log
 * @type {string}
 */
export const cadLog = "cadLog";

/**
 * Debug log
 * @type {string}
 */
export const debugLog = "debugLog";

/**
 * Database
 * @type {string}
 */
export const database = "database";

/**
 * Standard column width
 * @type {number}
 */
export const standardColWidth = 100;

/**
 * Narrow column width
 * @type {number}
 */
export const narrowColWidth = 96;

/**
 * Standard column width for logs table
 * @type {number}
 */
export const standardColWidthLogs = 150;


/**
 * Wide column for comma separated values
 * @type {number}
 */
export const wideCommaSeparated = 230;