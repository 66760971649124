import React, {Component} from "react";
import {API} from "aws-amplify";
import LoaderButton from "../components/LoaderButton";
import "./Trainer.css";
import * as columnDefsTrainers from "../libs/columnDefTrainers";
import {AgGridReact} from "ag-grid-react";
import * as userLib from "../libs/user-lib";
import {validateFormTrainer} from "../libs/user-lib";

export default class Trainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: null,
            isDeleting: null,
            pump: null,

            // make a copy of the JSON structure as the underlying structure is modified
            columnDefs: JSON.parse(JSON.stringify(columnDefsTrainers.columnDefsTrainers)),

            defaultColDef: {
                resizable: true
            },

            style: {
                width: '100%',
                // height: '364px'
                height: '110px'
            },

            isEditor: userLib.isCustomEditor(this.props.currentUser, 'trainer'),

            rowData: []
        };

        // disable tick box
        this.state.columnDefs[0].checkboxSelection = false;

        // update column definition
        columnDefsTrainers.setColDefTrainerForDataEntry(true, this);
    }

    onGridReady = params => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;

        const updateData = data => {
            this.setState({rowData: data});
        };

        updateData(this.state.rowData);
    };

    componentDidUpdate() {
        if (this.gridApi) {
            this.gridApi.doLayout();
        }
    }

    async componentDidMount() {
        try {
            const trainer = await this.getTrainer();
            if ((trainer) && (!trainer.hasOwnProperty('closedLoopSystem'))) {
                trainer.closedLoopSystem = "FX";
            }
            this.setState({rowData: [trainer]});
        } catch (e) {
            if (e.response && e.response.data && e.response.data.messageUser) {
                this.props.processAlert(e.response.data.messageUser)
            } else {
                alert(e);
            }
        }
        this.props.setUpdateTimeOutListener();
    }

    getTrainer() {
        return API.get("pumps", `/trainers/${this.props.match.params.id}`, {});
    }

    saveTrainer(trainer) {
        let attrib;
        for (attrib in trainer) {
            if (Object.prototype.hasOwnProperty.call(trainer, attrib)) {
                if ("" === attrib) {
                }
                attrib = null;
            }
        }
        return API.put("pumps", `/trainers/${this.props.match.params.id}`, {
            body: trainer
        });
    }

    deleteTrainer() {
        const options = {deleteItem: true};
        return API.put("pumps", `/trainers/${this.props.match.params.id}`, {
            body: options
        });
        // return API.del("pumps", `/trainers/${this.props.match.params.id}`, {});
    }

    handleSubmit = async event => {

        event.preventDefault();
        this.gridApi.stopEditing();
        if (validateFormTrainer(this)) {
            this.setState({isLoading: true});
            let element;
            try {
                element = this.gridApi.getDisplayedRowAtIndex(0).data;
                await this.saveTrainer(element);
                alert('Success. An email has been sent to the Trainer to inform him/her about his/her ' +
                    ' update of CamAPS trainer registration record.');
                // reload data
                this.props.setRowDataTrainers([]);
                this.props.history.push("/trainers");
            } catch (e) {
                if (e.response && e.response.data && e.response.data.messageUser) {
                    this.props.processAlert(e.response.data.messageUser)
                } else {
                    alert(e);
                }
                this.setState({isLoading: false});
            }
        }
    };

    handleDelete = async event => {
        event.preventDefault();

        const confirmed = window.confirm(
            "Are you sure you want to delete this trainer? This cannot be undone."
        );

        if (!confirmed) {
            return;
        }

        this.setState({isDeleting: true});

        try {
            await this.deleteTrainer();
            alert('Record deleted. An email has been sent to the Trainer to inform him/her ' +
                'about the deletion of the training record.');
            // reload data
            this.props.setRowDataTrainers([]);
            this.props.history.push("/trainers");
        } catch (e) {
            if (e.response && e.response.data && e.response.data.messageUser) {
                this.props.processAlert(e.response.data.messageUser)
            } else {
                alert(e);
            }
            this.setState({isDeleting: false});
        }
    };

    render() {
        return (
            <div className="Pumps">
                {this.state.rowData &&
                <form onSubmit={this.handleSubmit}>
                    <div
                        id="pumpGrid"
                        style={this.state.style}
                        className="ag-theme-balham"
                    >
                        <AgGridReact
                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            getRowHeight={this.state.getRowHeight}
                            onGridReady={this.onGridReady}
                            enableCellTextSelection={true}
                            singleClickEdit={true}
                            // components={this.state.components}
                            rowData={this.state.rowData}
                            headerHeight={58}
                        />
                    </div>
                    {this.state.isEditor &&
                    <LoaderButton
                        block
                        bsStyle="primary"
                        bsSize="large"
                        type="submit"
                        isLoading={this.state.isLoading}
                        text="Save"
                        loadingText="Saving…"
                    />
                    }
                    {this.state.isEditor &&
                    <LoaderButton
                        block
                        bsStyle="danger"
                        bsSize="large"
                        isLoading={this.state.isDeleting}
                        onClick={this.handleDelete}
                        text="Delete"
                        loadingText="Deleting…"
                    />
                    }
                </form>}
            </div>
        );
    }
}
