import * as globalsNotices from "./globalDefNotices";

/**
 * Shared ag-grid column definition for notices
 * @type {*[]}
 */
export const columnDefsNotices = [{
    headerName: "Notice ID", field: globalsNotices.noticeID,
    pinned: 'left',
    width: 110,
}, {
    headerName: "Email address", field: globalsNotices.userID,
    width: 240
}, {
    headerName: "Locale", field: globalsNotices.locale,
    width: 90
}, {
    headerName: "App version", field: globalsNotices.appVersion,
    width: 120
}, {
    headerName: "Variant", field: globalsNotices.variant,
    width: 90
}, {
    headerName: "Date shown", field: globalsNotices.dateNoticeShown,
    width: 240
}, {
    headerName: "Shown", field: globalsNotices.isNoticeShown,
    width: 80
}, {
    headerName: "Date confirmed", field: globalsNotices.dateUserConfirmed,
    width: 240
}, {
    headerName: "User confirmed reading", field: globalsNotices.isUserConfirmedRead,
    width: 150,
    cellClassRules: {
        'rag-red': function (params) {
            return (!params.value) || params.value === "No"
        }
    }
},
];

