/**
 * Attributed suffix
 * @type {string}
 */
export const attrib_suffix = "";

/**
 * Notice ID
 * @type {string}
 */
export const noticeID = "noticeID" + attrib_suffix;

/**
 * User email
 * @type {string}
 */
export const userID = "userID" + attrib_suffix;
/**
 * Locale for the version notice
 * @type {string}
 */
export const locale = "locale" + attrib_suffix;

/**
 * For which variant the message should be shown
 * @type {string}
 */
export const variant = "appVariant";

/**
 * App version
 * @type {string}
 */
export const appVersion = "appVersion";

/**
 * Date when notice shown
 * @type {string}
 */
export const dateNoticeShown = "dateNoticeShown" + attrib_suffix;

/**
 * Whether notice has been shown
 * @type {Number}
 */
export const isNoticeShown = "isNoticeShown";

/**
 * Date when user confirmed reading FSN
 * @type {Number}
 */
export const dateUserConfirmed = "dateUserConfirmed" + attrib_suffix;

/**
 * Whether user confirmed reading FSN
 * @type {Number}
 */
export const isUserConfirmedRead = "isUserConfirmedRead";



