/**
 * Attributed suffix
 * @type {string}
 */
export const attrib_suffix = "";

/**
 * Custome email
 * @type {string}
 */
export const exemptionID = "customerEmail" + attrib_suffix;

/**
 * Time when order made
 * @type {string}
 */
export const orderTime = "orderTime" + attrib_suffix;

/**
 * Supplied product for which VAT exemption is provided
 * @type {string}
 */
export const suppliedProduct = "suppliedProduct" + attrib_suffix;

/**
 * First name
 * @type {string}
 */
export const firstNameVatExempt = "firstNameVatExempt" + attrib_suffix;

/**
 * Last name
 * @type {string}
 */
export const lastNameVatExempt = "lastNameVatExempt" + attrib_suffix;

/**
 * Address line 1
 * @type {string}
 */
export const addressLine1VatExempt = "addressLine1VatExempt" + attrib_suffix;

/**
 * Address line 2
 * @type {string}
 */
export const addressLine2VatExempt = "addressLine2VatExempt" + attrib_suffix;

/**
 * Address line 3
 * @type {string}
 */
export const addressLine3VatExempt = "addressLine3VatExempt" + attrib_suffix;

/**
 * Postcode
 * @type {string}
 */
export const postcodeVatExempt = "postcodeVatExempt" + attrib_suffix;

/**
 * Health condition (locale)
 * @type {string}
 */
export const conditionVatExempt = "conditionVatExempt" + attrib_suffix;

/**
 * Signature of user
 * @type {string}
 */
export const signatureVatExempt = "signatureVatExempt" + attrib_suffix;

/**
 * Date signed
 * @type {string}
 */
export const dateSignedVatExempt = "dateSignedVatExempt" + attrib_suffix;

/**
 * Associated file
 * @type {string}
 */
export const fileNameVatExempt = "fileNameVatExempt" + attrib_suffix;

/**
 * When record created
 * @type {string}
 */
export const createdAt = "createdAt" + attrib_suffix;

/**
 * When record created
 * @type {string}
 */
export const lastModifiedAt = "lastModifiedAt" + attrib_suffix;